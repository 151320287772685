import React, { useState } from "react";
import focadot from "../../Images/Focadot.png";
import { Link } from "react-router-dom";
import "./Welcome.css";
import { useDispatch, useSelector } from "react-redux";
import { setField3 } from "../Redux/Forcadot";
import Swal from "sweetalert2";
import { logout } from "../Redux/signupSlice";
import vector from "../../Images/Vector.png";
function Welcome() {
  const [selectedValue, setSelectedValue] = useState(null);
  const name = useSelector((state) => state?.signupSlice?.user?.fName);
  const dispatch = useDispatch();
  let exp = useSelector((state) => state.fac);
  exp = { ...exp, experience: selectedValue };
  console.log("exp", exp);
  const path = name ? "/back" : "/";
  const handleButtonClick = (value) => {
    setSelectedValue(value);
  };

  const handleButtonClick1 = () => {
    if (!selectedValue) {
      Swal.fire({
        title: "Warning",
        text: "Please select Experience type",
        icon: "warning",
        confirmButtonColor: "#6f00fe",
        confirmButtonClass: "#6f00fe-ok-button",
        confirmButtonText: '<span style="color:#FFFFFF">OK</span>',
      });
    } else {
      dispatch(setField3(exp));
    }
  };

  console.log("selectedValue", selectedValue);

  return (
    <div>
      <div className="main_boxdiv">
        <div className="box_div">
          {/* #6f00fe portion */}
          <div className="upper_portion1">
            <div className="focadot_imgdiv">
              {/* <img src={focadot} /> */}
              <div className="logoWhite"></div>
            </div>
            <div className="vector_div">
              <div className="text_head">
                {name ? <span>Welcome, {name}!</span> : <span>Welcome</span>}
              </div>
            </div>
            <Link className="link_sign2" to={path}>
              <div className="vector_out">
                <img src={vector} alt="Vector" />
              </div>
            </Link>
          </div>
          {/* button section */}

          <div>
            <div className="text_div">
              <p className="p_text">
                What would you like to experience right now?
              </p>

              <button
                className={`Inner_button ${
                  selectedValue === "Relaxation" ? "active" : ""
                }`}
                onClick={() => handleButtonClick("Relaxation")}
              >
                Relaxation
              </button>
              <button
                className={`Inner_button ${
                  selectedValue === "Inner peace" ? "active" : ""
                }`}
                onClick={() => handleButtonClick("Inner peace")}
              >
                Inner peace
              </button>
              <button
                className={`Inner_button inspst ${
                  selectedValue === "Inspiration" ? "active" : ""
                }`}
                onClick={() => handleButtonClick("Inspiration")}
              >
                Inspiration
              </button>
              <button
                className={`Inner_button grat ${
                  selectedValue === "Gratitude" ? "active" : ""
                }`}
                onClick={() => handleButtonClick("Gratitude")}
              >
                Gratitude
              </button>
              <button
                className={`Inner_button ${
                  selectedValue === "Love" ? "active" : ""
                }`}
                onClick={() => handleButtonClick("Love")}
              >
                Love
              </button>
              <button
                className={`Inner_button inspst ${
                  selectedValue === "Focus" ? "active" : ""
                }`}
                onClick={() => handleButtonClick("Focus")}
              >
                Focus
              </button>
              <button
                className={`Inner_button inspst ${
                  selectedValue === "Empowerment" ? "active" : ""
                }`}
                onClick={() => handleButtonClick("Empowerment")}
              >
                Empowerment
              </button>
              <Link
                to={!selectedValue ? "" : "/duration"}
                className="link_sign"
              >
                <button className="next_button" onClick={handleButtonClick1}>
                  Next
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Welcome;
