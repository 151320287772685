import React, { useEffect, useState } from "react";
import axios from "axios";
import { setField3 } from "../Redux/Forcadot";
import { logout } from "../Redux/signupSlice";

import focadot from "../../Images/Focadot.png";
// import badge from "../../Images/badge.png";
import badge from "../../badge.svg";
import { Link, useNavigate } from "react-router-dom";
import "./Back.css";
import { useSelector, useDispatch } from "react-redux";
import { BASEURL } from "../../utils/serverInstance";
import { useLayoutEffect } from "react";

function Back() {
  const [aggregationResult, setAggregationResult] = useState();
  // const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  console.log("aggregationResult", aggregationResult);
  const dispatch = useDispatch();
  let exp = useSelector((state) => state.fac);

  const userId = useSelector((state) => state?.signupSlice?.user?._id);
  const count = useSelector((state) => state?.signupSlice?.user?.count);
  console.log("userId123", userId);
  const name = useSelector((state) => state?.signupSlice?.user?.fName);

  useEffect(() => {
    // setLoader(false);
    console.log("userId123456wewr", userId);

    if (userId === undefined) {
      exp = { ...exp, Flow: "Flow2" };
      dispatch(setField3(exp));
      navigate("/sign");
    } else {
      axios
        .get(`${BASEURL}/api/getTime/${userId}`)
        .then((response) => {
          console.log("responseduration", response?.data?.time);
          // setLoader(true);
          setAggregationResult(response?.data?.time || 0);
          console.log("response12345");
        })
        .catch((error) => console.error(error));
    }
  }, [userId]);

  // const formattedAggregationResult = aggregationResult?.toFixed(2);

  return (
    <div>
      <div className="main_boxdiv">
        <div className="back_box_div">
          {/* #6f00fe portion */}
          <div className="back_uper_portion">
            <div className="back_focadot_imgdiv">
              {/* <img src={focadot} /> */}
              <div className="logoWhite"></div>
              <p className="back_text_head">
                Welcome {count == 1 ? "" : "back"}, {name}!
              </p>
            </div>
          </div>

          {/* button section */}

          <div>
            <div className="back_text_div">
              <p className="back_p_text">Your Meditation Minutes</p>

              <div className="back_round_div">
                <div className="back_number">{aggregationResult}</div>
              </div>
              <div className="back_cong_div">
                <p className="back_cong">
                  <spna
                    className="back_cong_span"
                    style={{ fontWeight: "800" }}
                  >
                    Congratulations!
                  </spna>
                  <br />
                  You've meditated for {aggregationResult}
                  {` `}minutes!
                </p>
                <img src={badge} />
              </div>

              <Link className="link_sign" to="/meditation">
                {" "}
                <button className="back_Inner_button">
                  Select most recent
                </button>
              </Link>
              <Link className="link_sign" to="/welcome">
                {" "}
                <button
                  className="back_Inner_button start_fresh"
                  onClick={() =>
                    dispatch(
                      setField3({ feadback1: "", feadback2: "", feadback3: "" })
                    )
                  }
                >
                  Start fresh
                </button>
              </Link>
              <Link className="link_sign" to="/">
                {" "}
                <button
                  className="med_next_button1"
                  onClick={() => {
                    dispatch(logout());
                    dispatch(setField3());
                  }}
                >
                  Logout
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Back;
