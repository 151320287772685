import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import axios from "axios";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "./Admin.css";
import { BASEURL } from "../../utils/serverInstance";
import Pagination from "@mui/material/Pagination";

const UsersBehaviour = () => {
  const [Behaviours, setBehaviours] = useState([]);
  const [expandedUser, setExpandedUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const rowsPerPage = 15;

  const handleRowClick = (index) => {
    setExpandedUser((prevExpandedUser) =>
      prevExpandedUser === index ? null : index
    );
  };

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  useEffect(() => {
    setLoading(true);
    fetchBehaviorsData()
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, []);

  const fetchBehaviorsData = async () => {
    try {
      const response = await axios.get(`${BASEURL}/api/allfocadots`);
      console.log(response?.data, "filteredData");
      setBehaviours(response?.data);
    } catch (error) {
      console.error("Error fetching data1:", error);
    }
  };

  const offset = currentPage * rowsPerPage;
  const displayedRows = Behaviours?.slice(offset, offset + rowsPerPage);
  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage - 1);
  };
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
  };

  // Function to calculate and format the total time
  const calculateTotalTime = () => {
    const totalSeconds = Behaviours.reduce((total, item) => {
      // Check if item.totalTime is a valid number
      const totalTime = parseInt(item.user.totalTime);
      if (!isNaN(totalTime)) {
        console.log(`Processing totalTime: ${totalTime}`);
        return total + totalTime;
      }
      console.log(`Skipping invalid totalTime: ${item.user.totalTime}`);
      return total;
    }, 0);
    console.log(`Total seconds: ${totalSeconds}`);
    return formatTime(totalSeconds);
  };
  return (
    <>
      <div className="lowerfeedTable">
        <TableContainer component={Paper} style={{ backgroundColor: "none" }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className="headingArea13">Sr No</TableCell>
                <TableCell className="headingArea1">User Name</TableCell>
                <TableCell className="headingArea1">User Email</TableCell>
                <TableCell className="headingArea1">
                  Total Meditation Time({calculateTotalTime()})
                </TableCell>
                <TableCell className="headingArea13">No. of logins</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {displayedRows?.map((row, rowIndex) => (
                // Assuming you want to display all users in the 'user' array
                // row?.users?.map((user, userIndex) => (
                <React.Fragment key={`${row.name}-${rowIndex}`}>
                  {/* Main user row */}
                  <TableRow
                    onClick={() => handleRowClick(rowIndex)}
                    sx={{
                      cursor: "pointer",
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                    className="userMain"
                  >
                    <TableCell className="tableDetail">
                      {currentPage * rowsPerPage + rowIndex + 1}
                    </TableCell>
                    <TableCell className="tableDetail">
                      {row?.user?.fName}
                    </TableCell>
                    <TableCell className="tableDetail">
                      {row?.user?.email}
                    </TableCell>
                    <TableCell className="tableDetail">
                      {row?.user?.totalTimeFormatted} minutes
                    </TableCell>
                    <TableCell className="tableDetail">
                      {row?.user?.count}
                    </TableCell>
                  </TableRow>
                  {/* Additional details row */}
                  {expandedUser === rowIndex && (
                    <TableRow className="lowertable">
                      <TableCell colSpan={8}>
                        <div className="expTable">
                          <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                              <TableHead>
                                <TableRow style={{ borderBottom: "none" }}>
                                  <TableCell
                                    style={{ borderBottom: "none" }}
                                    className="headingArea"
                                  >
                                    Experience
                                  </TableCell>
                                  <TableCell
                                    style={{ borderBottom: "none" }}
                                    className="headingArea"
                                  >
                                    Meditation Time
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {row?.focadots.length === 0 ? (
                                  <TableRow>
                                    <TableCell
                                      colSpan={4}
                                      className="tableDetail2"
                                    >
                                      No saved meditations yet
                                    </TableCell>
                                  </TableRow>
                                ) : (
                                  row?.focadots.map(
                                    (focadots, focadotsIndex) => (
                                      <TableRow
                                        key={`${row?.name}-${focadotsIndex}`}
                                        sx={{
                                          "&:last-child td, &:last-child th": {
                                            border: 0,
                                          },
                                        }}
                                      >
                                        <TableCell
                                          style={{ borderBottom: "none" }}
                                          className="tableDetail2"
                                        >
                                          {focadots?.experience}
                                        </TableCell>
                                        <TableCell
                                          style={{ borderBottom: "none" }}
                                          className="tableDetail2"
                                        >
                                          {focadots?.durationn} minutes
                                        </TableCell>
                                      </TableRow>
                                    )
                                  )
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>
                      </TableCell>
                    </TableRow>
                  )}
                </React.Fragment>
                // ))
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <div className="custom-pagination">
        <Pagination
          count={Math.ceil(Behaviours?.length / rowsPerPage)}
          page={currentPage + 1}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
          color="primary"
        />
      </div>
    </>
  );
};

export default UsersBehaviour;
