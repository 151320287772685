import React, { useEffect, useState } from "react";
import focadot from "../../Images/Focadot.png";
import vector from "../../Images/Vector.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./Meditation.css";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../Redux/signupSlice.js";
import axios from "axios";
import { setField3 } from "../Redux/Forcadot";
import { updateFeedbackToken } from "../Redux/signupSlice";

import { setOpacityValue } from "../Redux/ColorSlice";
import { BASEURL } from "../../utils/serverInstance";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import { MdDeleteForever } from "react-icons/md";

function DeleteMeditation({ show, onHide, itemId, onSuccessfulDelete }) {
  const handleDelete = async () => {
    try {
      const response = await axios.delete(
        `${BASEURL}/api/deletefacodot/${itemId}`
      );
      console.log("ResponseData:", response.data);

      Swal.fire({
        title: response.data.message,
        icon: "success",
        iconColor: "#6f00fe",
        showCloseButton: true,
        showCancelButton: false,
        confirmButtonColor: "#6f00fe",
        confirmButtonText: "OK",
        position: "center",
        customClass: {
          container: "swal-container",
          content: "swal-content",
          title: "swal-title",
          actions: "swal-actions",
        },
      });
      onHide();
      onSuccessfulDelete();
    } catch (error) {
      console.error("Error deleting item:", error);
    }
  };
  return (
    <Modal
      size="lg"
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div style={{ textAlign: "center" }}>
          Are you sure you want to delete this meditation?
        </div>
        <div className="meditaButtons">
          <button className="yeSBtn" onClick={handleDelete}>
            Yes
          </button>
          <button className="yeSBtn" onClick={onHide}>
            Cancel
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

function Meditation() {
  const [aggregationResult, setAggregationResult] = useState([]);
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  console.log("aggregationResult", aggregationResult);
  const userId = useSelector((state) => state?.signupSlice?.user?._id);
  const user = useSelector((state) => state?.signupSlice?.user);
  let exp = useSelector((state) => state.fac);

  const name = useSelector((state) => state?.signupSlice?.user?.fName);
  const [modalShow, setModalShow] = React.useState(false);
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [successfulDelete, setSuccessfulDelete] = useState(false);

  useEffect(() => {
    if (userId) {
      axios
        .get(`${BASEURL}/api/focadots/${userId}`)
        .then((response) => {
          console.log("response12344", response);
          setAggregationResult(response?.data?.focadots);
          // dispatch(updateFeedbackToken(response?.data?.FeedabkToken));
          console.log(
            response.data.FeedabkToken,
            "response?.data?.FeedabkToken"
          );

          setLoader(true);

          setLoader(true);
        })
        .catch((error) => console.error(error));
    } else {
      exp = { ...exp, Flow: "Flow2" };
      dispatch(setField3(exp));
      navigate("/sign");
    }
  }, [successfulDelete]);

  console.log("aggregationResultaggregationResult", aggregationResult);
  const navigate = useNavigate();
  const handleSubmitData = (item) => {
    // ... (unchanged)

    // Dispatch the setOpacityValue action with the 'opacity' value from the clicked 'item'.
    dispatch(setOpacityValue(item?.opacity || 50));

    // Dispatch the setField3 action with the entire 'item' object.
    dispatch(setField3(item));

    // Navigate to "/animate"
    navigate("/animate");
  };

  const handleDeleteIconClick = (itemId) => {
    setDeleteItemId(itemId);
    setDeleteModal(true);
  };

  return (
    <>
      <DeleteMeditation
        show={deleteModal}
        onHide={() => setDeleteModal(false)}
        itemId={deleteItemId}
        onSuccessfulDelete={() => setSuccessfulDelete(!successfulDelete)}
      />
      <div>
        <div className="main_boxdiv">
          <div className="meditation_box_div">
            {/* #6f00fe portion */}
            <div className="medit_portion">
              <div className="med_imgdiv">
                {/* <img src={focadot} /> */}
                <div className="logoWhite"></div>
              </div>

              <div className="med_div">
                <Link className="med_out" to="/back">
                  <div className="link_sign signing">
                    <img src={vector} />
                  </div>
                </Link>
                <div className="med_divs_text">
                  <p className="med_text">
                    Select from your previous meditations
                  </p>
                </div>
              </div>
            </div>
            <div className="div-for-begin">
              <div className="med_text_div">
                {loader && aggregationResult
                  ? aggregationResult?.map((item, index) => {
                      return (
                        <div className="scroll-div" key={index}>
                          <button
                            className="med_button relax"
                            onClick={() => {
                              handleSubmitData(item);
                            }}
                          >
                            {item?.experience}
                          </button>
                          <MdDeleteForever
                            className="detleicon"
                            onClick={() => handleDeleteIconClick(item?._id)}
                          />
                        </div>
                      );
                    })
                  : ""}
              </div>
              <Link className="link_sign" to="/welcome">
                <button
                  className="med_next_button"
                  onClick={() =>
                    dispatch(
                      setField3({ feadback1: "", feadback2: "", feadback3: "" })
                    )
                  }
                >
                  Begin
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Meditation;
