import React, { useEffect, useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

// import "./styles.css";

// import required modules
import SwiperCore, { EffectCoverflow, Pagination } from "swiper";
import { Rings } from "react-loader-spinner";

import one from "../../Images/one.jpg";
import two from "../../Images/two.jpg";
import three from "../../Images/three.jpg";
import four from "../../Images/four.jpg";
import five from "../../Images/five.jpg";
import six from "../../Images/six.jpg";
import seven from "../../Images/seven.jpg";
import eight from "../../Images/eight.jpg";
import nine from "../../Images/nine.jpg";
import ten from "../../Images/ten.jpg";
import eleven from "../../Images/eleven.jpg";
import "../Animation/Animation.css";
import focadot from "../../Images/Focadot.png";
import vector from "../../Images/Vector.png";
import pic from "../../Images/pic.png";
import s1 from "../../Images/sky1/s1.jpg";
import s2 from "../../Images/sky1/s2.jpg";
import s3 from "../../Images/sky1/s3.jpg";
import s4 from "../../Images/sky1/s4.jpg";
import s5 from "../../Images/sky1/s5.jpg";
//mountain
import m1 from "../../Images/mountain1/m1.jpg";
import m2 from "../../Images/mountain1/m2.jpg";
import m3 from "../../Images/mountain1/m3.jpg";
import m4 from "../../Images/mountain1/m4.jpg";
import m5 from "../../Images/mountain1/m5.jpg";
import m6 from "../../Images/mountain1/m6.jpg";
import m7 from "../../Images/mountain1/m7.jpg";
import m8 from "../../Images/mountain1/m8.jpg";
import m9 from "../../Images/mountain1/m9.jpg";
import m10 from "../../Images/mountain1/m10.jpg";
import m11 from "../../Images/mountain1/m11.jpg";
import m12 from "../../Images/mountain1/m12.jpg";
import m13 from "../../Images/mountain1/m13.jpg";
///forest
import f1 from "../../Images/forest1/f1.jpg";
import f2 from "../../Images/forest1/f2.jpg";
import f3 from "../../Images/forest1/f3.jpg";
import f4 from "../../Images/forest1/f4.jpg";
import f5 from "../../Images/forest1/f5.jpg";
import f6 from "../../Images/forest1/f6.jpg";
import f7 from "../../Images/forest1/f7.jpg";
import f8 from "../../Images/forest1/f8.jpg";
import f9 from "../../Images/forest1/f9.jpg";
import f10 from "../../Images/forest1/f10.jpg";
import f11 from "../../Images/forest1/f11.jpg";
import f12 from "../../Images/forest1/f12.jpg";
///desert
import d1 from "../../Images/desert1/d1.jpg";
import d2 from "../../Images/desert1/d2.jpg";
import d3 from "../../Images/desert1/d3.jpg";
import d4 from "../../Images/desert1/d4.jpg";
import d5 from "../../Images/desert1/d5.jpg";
import d6 from "../../Images/desert1/d6.jpg";
import d7 from "../../Images/desert1/d7.jpg";
import d8 from "../../Images/desert1/d8.jpg";
import d9 from "../../Images/desert1/d9.jpg";
import d10 from "../../Images/desert1/d10.jpg";
import d11 from "../../Images/desert1/d11.jpg";

///beach
import b1 from "../../Images/beach1/b1.jpg";
import b2 from "../../Images/beach1/b2.jpg";
import b3 from "../../Images/beach1/b3.jpg";
import b4 from "../../Images/beach1/b4.jpg";
import b5 from "../../Images/beach1/b5.jpg";
import b6 from "../../Images/beach1/b6.jpg";
import b7 from "../../Images/beach1/b7.jpg";
import b8 from "../../Images/beach1/b8.jpg";

///
import pic1 from "../../Images/pic1.png";
import pic2 from "../../Images/pic2.png";
import pic3 from "../../Images/pic3.png";
import tick from "../../Images/tick.png";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setField3 } from "../Redux/Forcadot";
import "./Theme.css";
SwiperCore.use([EffectCoverflow, Pagination]);
function Theme() {
  const [stateimg, setImg] = useState();
  const [selectedType, setSelectedType] = useState("sky");
  console.log("stateimg", stateimg);

  const data = useSelector((state) => state.fac);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0); // Track the active slide index
  const [array, setArray] = useState([
    {
      img: m1,
      type: "mountain",
    },
    {
      img: m2,
      type: "mountain",
    },
    {
      img: m3,
      type: "mountain",
    },
    {
      img: m4,
      type: "mountain",
    },
    {
      img: m5,
      type: "mountain",
    },
    {
      img: m6,
      type: "mountain",
    },
    {
      img: m7,
      type: "mountain",
    },
    {
      img: m8,
      type: "mountain",
    },
    {
      img: m9,
      type: "mountain",
    },
    {
      img: m10,
      type: "mountain",
    },
    {
      img: m11,
      type: "mountain",
    },
    {
      img: m12,
      type: "mountain",
    },
    {
      img: m13,
      type: "mountain",
    },
    {
      img: f1,
      type: "forest",
    },
    {
      img: f2,
      type: "forest",
    },
    {
      img: f3,
      type: "forest",
    },
    {
      img: f4,
      type: "forest",
    },
    {
      img: f5,
      type: "forest",
    },
    {
      img: f6,
      type: "forest",
    },
    {
      img: f7,
      type: "forest",
    },
    {
      img: f8,
      type: "forest",
    },
    {
      img: f9,
      type: "forest",
    },
    {
      img: f10,
      type: "forest",
    },
    {
      img: f11,
      type: "forest",
    },
    {
      img: f12,
      type: "forest",
    },

    {
      img: d1,
      type: "desert",
    },
    {
      img: d2,
      type: "desert",
    },
    {
      img: d3,
      type: "desert",
    },
    {
      img: d4,
      type: "desert",
    },
    {
      img: d5,
      type: "desert",
    },
    {
      img: d6,
      type: "desert",
    },
    {
      img: d7,
      type: "desert",
    },
    {
      img: d8,
      type: "desert",
    },
    {
      img: d9,
      type: "desert",
    },
    {
      img: d10,
      type: "desert",
    },
    {
      img: d11,
      type: "desert",
    },

    {
      img: b1,
      type: "beach",
    },
    {
      img: b2,
      type: "beach",
    },
    {
      img: b3,
      type: "beach",
    },
    {
      img: b4,
      type: "beach",
    },
    {
      img: b5,
      type: "beach",
    },
    {
      img: b6,
      type: "beach",
    },
    {
      img: b7,
      type: "beach",
    },
    {
      img: b8,
      type: "beach",
    },

    {
      img: s1,
      type: "sky",
    },
    {
      img: s2,
      type: "sky",
    },
    {
      img: s3,
      type: "sky",
    },
    {
      img: s4,
      type: "sky",
    },
    {
      img: s5,
      type: "sky",
    },
  ]);

  const dispatch = useDispatch();

  const selectImage = (index) => {
    const selectedImage = array[index].img;

    setImg(selectedImage);
  };

  const onSlideChange = (swiper) => {
    setActiveSlideIndex(swiper.activeIndex);
    const centerImageIndex = swiper.realIndex; // Use realIndex instead of activeIndex
    const centerImage = filteredArray[centerImageIndex].img;
    setImg(centerImage);
  };

  const filteredArray = selectedType
    ? array.filter((item) => item.type === selectedType)
    : array.filter((item) => item.type === "sky");
  let exp = useSelector((state) => state.fac);
  exp = { ...exp, picture: stateimg, imageType: selectedType };
  console.log("selectedType", selectedType);

  const handlePicture = () => {
    dispatch(setField3(exp));
  };
  console.log("array", array);

  const swiperRef = useRef(null); // Ref for the Swiper component

  useEffect(() => {
    if (swiperRef.current) {
      // Check if the Swiper component is mounted
      const swiperInstance = swiperRef.current.swiper;
      if (swiperInstance) {
        const centerImageIndex = swiperInstance.activeIndex; // Get the center image index
        selectImage(centerImageIndex); // Call selectImage with the center image index
      }
    }
  }, []);
  const [loadedImages, setLoadedImages] = useState([]);

  const handleImageLoad = (index) => {
    console.log(index, "calling");
    if (!loadedImages.includes(index)) {
      setLoadedImages((prevLoadedImages) => [...prevLoadedImages, index]);
    }
  };
  return (
    <div>
      <div className="main_boxdiv">
        <div className="the_box_div">
          {/* #6f00fe portion */}
          <div className="the_portion">
            <div className="the_imgdiv">
              {/* <img src={focadot} /> */}
              <div className="logoWhite"></div>
            </div>

            <div className="the_div">
              <Link className="link_sign" to="/breath">
                <div className="the_out">
                  <img src={vector} />
                </div>
              </Link>
              <div className="the_divs_text">
                <div className="the_text">Theme</div>
              </div>
            </div>
          </div>

          {/* lower portion */}

          <div className="the_lowerdiv">
            <p className="the_lowertext">
              Select your theme for {data?.experience}
            </p>
            <div className="selector">
              <select
                value={selectedType}
                onChange={(e) => setSelectedType(e.target.value)}
                className="custom-selector"
              >
                {/* <option value="">Select Image Type</option> */}
                <option value="sky">Sky</option>
                <option value="mountain">Mountain</option>
                <option value="beach">Beach</option>
                <option value="desert">Desert</option>
                <option value="forest">Forest</option>
              </select>
            </div>
          </div>
          {!filteredArray ? (
            <div className="loader">Loading...</div>
          ) : (
            <div className="animate123">
              <Swiper
                effect="coverflow"
                grabCursor={true}
                centeredSlides={true}
                slidesPerView="auto"
                coverflowEffect={{
                  rotate: 70,
                  stretch: 0,
                  depth: 100,
                  modifier: 1,
                  slideShadows: true,
                }}
                pagination={true}
                className="mySwiper"
                initialSlide={0}
                ref={swiperRef}
                // effect={"coverflow"}
                // grabCursor={true}
                // centeredSlides={true}
                // slidesPerView={"auto"}
                // coverflowEffect={{
                //   rotate: 70,
                //   stretch: 0,
                //   depth: 100,
                //   modifier: 1,
                //   slideShadows: true,
                // }}
                // pagination={true}
                // modules={[EffectCoverflow, Pagination]}
                // className="mySwiper"
                onSlideChange={onSlideChange}
                // initialSlide={0} // Add initialSlide prop and set it to 0
              >
                {/* {filteredArray.map(({ img }, index) => {
                return (
                  <SwiperSlide key={index}>
                    <img
                      style={{ cursor: "pointer" }}
                      // onClick={() => selectImage(index)}
                      src={img}
                      className={activeSlideIndex === index ? "active-slide" : "borderRad"} // Apply active-slide class for the active slide
                    />
                  </SwiperSlide>
                );
              })} */}
                {filteredArray?.map((image, index) => (
                  <SwiperSlide key={index}>
                    <div
                      style={{
                        position: "relative",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      {loadedImages.includes(index) ? null : (
                        <div
                          className="loading-overlay"
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            background: "rgba(255, 255, 255, 0.8)",
                          }}
                        >
                          <Rings
                            height="80"
                            width="80"
                            color="#6f00fe"
                            radius="6"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                            ariaLabel="rings-loading"
                          />
                        </div>
                      )}
                      <img
                        onLoad={() => handleImageLoad(index)}
                        src={image.img}
                        alt={`Slide ${index}`}
                        style={{
                          cursor: "pointer",
                          width: "100%",
                          height: "100%",
                        }}
                        className={
                          activeSlideIndex === index
                            ? "active-slide"
                            : "borderRad"
                        }
                      />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          )}

          <div className="next_theme">
            <Link
              to={{
                pathname: "/color",
              }}
              state={{ data: array.findIndex((d) => d.checked === true) }}
              className="link_sign"
            >
              {" "}
              <button className="theme_next_button" onClick={handlePicture}>
                Next
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Theme;
