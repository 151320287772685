import React, { useState, useEffect } from "react";
import axios from "axios";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "./Admin.css";
import { BASEURL } from "../../utils/serverInstance";
//mountain
import s1 from "../../Images/sky/s1.jpg";
import s2 from "../../Images/sky/s2.jpg";
import s3 from "../../Images/sky/s3.jpg";
import s4 from "../../Images/sky/s4.jpg";
import s5 from "../../Images/sky/s5.jpg";
//mountain
import m1 from "../../Images/mountain/m1.jpg";
import m2 from "../../Images/mountain/m2.jpg";
import m3 from "../../Images/mountain/m3.jpg";
import m4 from "../../Images/mountain/m4.jpg";
import m5 from "../../Images/mountain/m5.jpg";
import m6 from "../../Images/mountain/m6.jpg";
import m7 from "../../Images/mountain/m7.jpg";
import m8 from "../../Images/mountain/m8.jpg";
import m9 from "../../Images/mountain/m9.jpg";
import m10 from "../../Images/mountain/m10.jpg";
import m11 from "../../Images/mountain/m11.jpg";
import m12 from "../../Images/mountain/m12.jpg";
import m13 from "../../Images/mountain/m13.jpg";
///forest
import f1 from "../../Images/forest/f1.jpg";
import f2 from "../../Images/forest/f2.jpg";
import f3 from "../../Images/forest/f3.jpg";
import f4 from "../../Images/forest/f4.jpg";
import f5 from "../../Images/forest/f5.jpg";
import f6 from "../../Images/forest/f6.jpg";
import f7 from "../../Images/forest/f7.jpg";
import f8 from "../../Images/forest/f8.jpg";
import f9 from "../../Images/forest/f9.jpg";
import f10 from "../../Images/forest/f10.jpg";
import f11 from "../../Images/forest/f11.jpg";
import f12 from "../../Images/forest/f12.jpg";
///desert
import d1 from "../../Images/desert/d1.jpg";
import d2 from "../../Images/desert/d2.jpg";
import d3 from "../../Images/desert/d3.jpg";
import d4 from "../../Images/desert/d4.jpg";
import d5 from "../../Images/desert/d5.jpg";
import d6 from "../../Images/desert/d6.jpg";
import d7 from "../../Images/desert/d7.jpg";
import d8 from "../../Images/desert/d8.jpg";
import d9 from "../../Images/desert/d9.jpg";
import d10 from "../../Images/desert/d10.jpg";
import d11 from "../../Images/desert/d11.jpg";

///beach
import b1 from "../../Images/beach/b1.jpg";
import b2 from "../../Images/beach/b2.jpg";
import b3 from "../../Images/beach/b3.jpg";
import b4 from "../../Images/beach/b4.jpg";
import b5 from "../../Images/beach/b5.jpg";
import b6 from "../../Images/beach/b6.jpg";
import b7 from "../../Images/beach/b7.jpg";
import b8 from "../../Images/beach/b8.jpg";
import home from "../../Images/home.png";

const PopularCategory = () => {
  const [data, setData] = useState([]);
  const [image, seImage] = useState();

  useEffect(() => {
    fetchBehaviors();
  }, []);

  const fetchBehaviors = () => {
    axios
      .get(`${BASEURL}/api/u/admin/popular-backgrounds`)
      .then((response) => {
        const filterData = response.data.filter(
          (item) => item.imageType !== null
        );
        const sortedData = filterData.sort(
          (a, b) => b.totalCount - a.totalCount
        );
        setData(sortedData);
        console.log(response, "datadfsdv");
        response.data.forEach((item) => {
          const textFromBackend = item.mostPopularImage.picture;
          const desiredPart = textFromBackend.substring(
            14,
            textFromBackend.length - 25
          );
          seImage(desiredPart);
          console.log(desiredPart, "desiredpart");
        });
      })
      .catch((error) => {
        console.error("Error fetching data1:", error);
      });
  };

  const getImageForType = (imagetype, imageName) => {
    console.log(imageName, "imageNamewer");
    console.log(imagetype, "imagtypimagetypeimagetypee");
    switch (true) {
      case imagetype === "mountain" && imageName === "m1":
        return m1;
      case imagetype === "mountain" && imageName === "m2":
        return m2;
      case imagetype === "mountain" && imageName === "m3":
        return m3;
      case imagetype === "mountain" && imageName === "m4":
        return m4;
      case imagetype === "mountain" && imageName === "m5":
        return m5;
      case imagetype === "mountain" && imageName === "m6":
        return m6;
      case imagetype === "mountain" && imageName === "m7":
        return m7;
      case imagetype === "mountain" && imageName === "m8":
        return m8;
      case imagetype === "mountain" && imageName === "m9":
        return m9;
      case imagetype === "mountain" && imageName === "m10":
        return m10;
      case imagetype === "mountain" && imageName === "m11":
        return m11;
      case imagetype === "mountain" && imageName === "m12":
        return m12;
      case imagetype === "mountain" && imageName === "m13":
        return m13;
      case imagetype === "sky" && imageName === "m1":
        return s1;
      case imagetype === "sky" && imageName === "s2":
        return s2;
      case (imagetype === "sky" && imageName === "s3") || imageName === "m3":
        return s3;
      case (imagetype === "sky" && imageName === "s4") || imageName === "m4":
        return s4;
      case (imagetype === "sky" && imageName === "s5") || imageName === "m5":
        return s5;
      case imagetype === "forest" && imageName === "m1":
        return f1;
      case (imagetype === "forest" && imageName === "f2") || imageName === "m2":
        return f2;
      case (imagetype === "forest" && imageName === "f3") || imageName === "m3":
        return f3;
      case (imagetype === "forest" && imageName === "f4") || imageName === "m4":
        return f4;
      case (imagetype === "forest" && imageName === "f5") || imageName === "m5":
        return f5;
      case imagetype === "forest" && imageName === "f6":
        return f6;
      case (imagetype === "forest" && imageName === "f7") || imageName === "m2":
        return f7;
      case (imagetype === "forest" && imageName === "f8") || imageName === "m3":
        return f8;
      case (imagetype === "forest" && imageName === "f9") || imageName === "m4":
        return f9;
      case (imagetype === "forest" && imageName === "f10") ||
        imageName === "m5":
        return f10;
      case (imagetype === "forest" && imageName === "f11") ||
        imageName === "m3":
        return f11;
      case (imagetype === "forest" && imageName === "f12") ||
        imageName === "m4":
        return f12;
      case imagetype === "desert" && imageName === "m1":
        return d1;
      case (imagetype === "desert" && imageName === "d2") || imageName === "m4":
        return d2;
      case (imagetype === "desert" && imageName === "d3") || imageName === "m5":
        return d3;
      case (imagetype === "desert" && imageName === "d4") || imageName === "m4":
        return d4;
      case (imagetype === "desert" && imageName === "d5") || imageName === "m2":
        return d5;
      case (imagetype === "desert" && imageName === "d6") || imageName === "m3":
        return d6;
      case (imagetype === "desert" && imageName === "d7") || imageName === "m4":
        return d7;
      case (imagetype === "desert" && imageName === "d8") || imageName === "m5":
        return d8;
      case (imagetype === "desert" && imageName === "d9") || imageName === "m3":
        return d9;
      case (imagetype === "desert" && imageName === "d10") ||
        imageName === "m4":
        return d10;
      case (imagetype === "desert" && imageName === "d11") ||
        imageName === "m5":
        return d11;
      case imagetype === "beach" && imageName === "m1":
        return b1;
      case (imagetype === "beach" && imageName === "b2") || imageName === "m2":
        return b2;
      case (imagetype === "beach" && imageName === "b3") || imageName === "m3":
        return b3;
      case (imagetype === "beach" && imageName === "b4") || imageName === "m4":
        return b4;
      case (imagetype === "beach" && imageName === "b5") || imageName === "m5":
        return b5;
      case (imagetype === "beach" && imageName === "b6") || imageName === "m3":
        return b6;
      case (imagetype === "beach" && imageName === "b7") || imageName === "m4":
        return b7;
      case (imagetype === "beach" && imageName === "b8") || imageName === "m5":
        return b8;
      case "beech":
        return imageName === "m4" ? m4 : home;
      default:
        return home; // A default image if the imagetype is not found or imageName doesn't match any known images
    }
  };
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className="headingArea">Background Category</TableCell>
            <TableCell className="headingArea">Category Total Used</TableCell>
            <TableCell className="headingArea">Background Image</TableCell>
            <TableCell className="headingArea">Image total Used</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(
            (row, index) =>
              row?.imageType !== null &&
              row?.imageType !== "" && (
                <TableRow
                  key={row}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  {console.log("dddd", row?.imageType)}
                  <TableCell className="tableDetail">
                    {row?.imageType}
                  </TableCell>
                  <TableCell className="tableDetail">
                    {row?.totalCount}
                  </TableCell>
                  <TableCell className="tableDetail">
                    <img
                      src={getImageForType(
                        row?.imageType,
                        row.mostPopularImage.picture.substring(
                          14,
                          row.mostPopularImage.picture.length - 25
                        )
                      )}
                      className="imagePopular"
                      alt=""
                    />
                  </TableCell>
                  <TableCell className="tableDetail">
                    {row?.mostPopularImage?.count}
                  </TableCell>
                </TableRow>
              )
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PopularCategory;
