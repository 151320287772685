import React, { useState, useEffect } from "react";
import axios from "axios";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "./Admin.css";
import { BASEURL } from "../../utils/serverInstance";

const PopularExperience = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchExperiences();
  }, []);

  const fetchExperiences = () => {
    axios
      .get(`${BASEURL}/api/u/experienceCounts`)
      .then((response) => {
        setData(response.data);
        console.log(response.data, "popularexperience");
      })
      .catch((error) => {
        console.error("Error fetching data1:", error);
      });
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className="headingArea">Experience</TableCell>
            <TableCell className="headingArea">Used Number</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow
              key={row}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              {console.log("dddd", row?.imageType)}
              <TableCell className="tableDetail">{row?._id}</TableCell>
              <TableCell className="tableDetail">{row?.count}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PopularExperience;
