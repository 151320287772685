import React from 'react'
import './FeedBack/FeedBack.css'
import AdminNavbar from './AdminNavbar'
import PopularCategory from './PopularCategory'
import PopularExperience from './PopularExperience'

const PopularItems = () => {
  return (
    <>
      <div className="feedOuter">
        <div className="navOuter">
          <AdminNavbar />
        </div>
        <div className='feedMain'>
          <div className="feedinner">
            <div className="feedText">Popular items:</div>
            <div className='feedTableArea'>
              <PopularCategory/>
            </div>
          </div>
          <div className="feedinner">
            <div className="feedText">Popular Experiences:</div>
            <div className='feedTableArea'>
              <PopularExperience/>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default PopularItems
