import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setField3 } from "../Redux/Forcadot";

import focadot from "../../Images/Focadot.png";
import vector from "../../Images/Vector.png";
import round from "../../Images/round.png";
import logo from "../../Images/logo1.png";
import home from "../../Images/undraw.png";
import red_image from "../../Images/Focared.png";
import { Link } from "react-router-dom";
import "./Home.css";

function Home() {
  const [flow, setFlow] = useState("");
  const dispatch = useDispatch();
  let exp = useSelector((state) => state.fac);
  exp = { ...exp, Flow: "Flow" };

  const handleFlow1 = () => {
    exp = { ...exp, Flow: "Flow1" }; // Update the exp state here

    dispatch(setField3(exp));
  };

  const handleFlow2 = () => {
    exp = { ...exp, Flow: "Flow2" }; // Update the exp state here

    dispatch(setField3(exp));
  };

  return (
    <div>
      <div className="main_boxdiv">
        <div className="home_box_div">
          {/* #6f00fe portion */}
          <div className="home_portion">
            <div className="lgo_div">
              <span className="home_text">Focadot</span>
              {/* <img className="home_logo" src={logo} /> */}
              <div className="home_logo"></div>
            </div>
          </div>

          {/* lower portion */}
          <div className="home_lowerdiv">
            <p className="home_wel">Welcome!</p>
            <div className="red_div">
              <img className="yogaIcon" src={home} />
              <img className="red_home" src={red_image} />
            </div>
            <p className="home_med">Meditation & mindfulness made simple</p>
            <p className="home_focodat">
              Focadot is the World's most simple, powerful and rewarding way to
              experience mindfulness - enjoy!
            </p>

            <Link to="/welcome" className="link_sign">
              {" "}
              <button className="home_next_button" onClick={handleFlow1}>
                Meditate Now For Free
              </button>
            </Link>
            <div className="home_sign">
              Already have an account ?{" "}
              <Link to="/sign" className="link_sign">
                {" "}
                <span className="span_sign" onClick={handleFlow2}>
                  Sign In
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
