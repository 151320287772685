import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { login, setUser } from "../Redux/signupSlice";

import logo from "../../Images/logo1.png";
import Form from "react-bootstrap/Form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import axios from "axios";
import { BASEURL } from "../../utils/serverInstance";

function SignIn() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let exp = useSelector((state) => state.fac.Flow);
  // // exp = { ...exp, Flow: "Flow" };
  // console.log(exp,"exp");

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `${BASEURL}/api/loginCustomer`,
        formData
      );
      console.log("userlogin Detail", response);

      const token = response.data.token;
      dispatch(setUser(response?.data.user));
      Swal.fire({
        title: response.data.message,
        showCancelButton: false,
        confirmButtonColor: "#6f00fe",
        confirmButtonText: "Ok",
        customClass: {
          confirmButton: "custom-confirm-button",
        },
      });

      if (exp === "Flow1") {
        navigate("/Animate");
      } else if (exp === "Flow2") {
        navigate("/back");
      }
    } catch (err) {
      Swal.fire({
        title: err.response.data.message,
        showCancelButton: false,
        confirmButtonColor: "#6f00fe",
        confirmButtonText: "Ok",
        customClass: {
          confirmButton: "custom-confirm-button",
        },
      });
    }
  };

  return (
    <div>
      <div className="main_boxdiv">
        <div className="sign_box_div">
          {/* #6f00fe portion */}
          <div className="sign_portion">
            <div className="sign_div">
              <span className="sign_text">Focadot</span>
              <img className="sign_logo" src={logo} />
            </div>
          </div>

          {/* lower portion */}
          <div className="sign_lowerdiv">
            <p className="sign_wel">Welcome back fellow Focadota!</p>

            <Form onSubmit={handleSubmit}>
              <Form.Control
                type="email"
                placeholder="Email"
                className="sign_fname sign_bot"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
              <Form.Control
                type="password"
                placeholder="Password"
                className="sign_fname sign_botms"
                name="password"
                passwrod
                value={formData.password}
                onChange={handleChange}
              />
              <div className="forgetArea">
                <button type="submit" className="sign_button">
                  Sign In & Meditate!
                </button>
                <Link to="/forgot" className="forgetText">
                  <div className="sign_sign">Forgot Password</div>
                </Link>
                <Link to="/signup" className="forgetText1">
                  <div className="sign_sign">Or SignUp</div>
                </Link>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignIn;
