import React, { useContext, useState } from "react";
import "./App.css";
import "@fontsource/poppins";
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  Navigate,
  Outlet,
} from "react-router-dom";
import Welcome from "./Components/Welcome/Welcome";
import Duration from "./Components/Duration/Duration";
import Breath from "./Components/Breath/Breath";
import Meditation from "./Components/Meditation/Meditation";
import Back from "./Components/Wback/Back";
import Almost from "./Components/Almost/Almost";
import Feedback from "./Components/Feedback/Feedback";
import Home from "./Components/Home/Home";
import SignIn from "./Components/SignIn/SignIn";
import Theme from "./Components/Theme/Theme";
import Color from "./Components/Color/Color";
import Animate from "./Components/Animate/Animate";
import Modalone from "./Components/Modal/Modalone";
import Modaltwo from "./Components/Modal/Modaltwo";
import Animation from "./Components/Animation/Animation";
import ForgotPassword from "./Components/forgetPassword/ForgotPassword";
import ResetPassword from "./Components/ResetPassword/ResetPassword";
import AdminHome from "./Components/AdminPanel/AdminHome";
import FeedBack from "./Components/AdminPanel/FeedBack/FeedBack";
import PopularItems from "./Components/AdminPanel/PopularItems";
import Savefocadot from "./Components/SaveFocadot/Savefocadot";
import SignUp from "./Components/Signup/SignUp";
import AdminSignIn from "./Components/AdminPanel/AdminSignIn";



const PrivateRoutes = () => {
  const [token, setAdminToken] = useState(sessionStorage?.getItem("token"));
  // sessionStorage.setItem("token",res.token)

  return token !== null ? <Outlet /> : <Navigate to="/admin" />;
};
const Redirect = () => {
  const [token, setAdminToken] = useState(sessionStorage?.getItem("token"));

  return token !== null ? <Navigate to="/behavior" /> : <Outlet />;
};



function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/meditation" element={<Meditation />} />
          <Route path="/welcome" element={<Welcome />} />
          <Route path="/duration" element={<Duration />} />
          <Route path="/breath" element={<Breath />} />{" "}
          <Route exact path="/sign" element={<SignIn />} />
          <Route path="/back" element={<Back />} />
          <Route path="/almost" element={<Almost />} />
          <Route path="/feedback" element={<Feedback />} />
          <Route exact path="/theme" element={<Theme />} />
          <Route path="/color" element={<Color />} />
          <Route path="/animate" element={<Animate />} />
          <Route path="/modalone" element={<Modalone />} />
          <Route path="/modaltwo" element={<Modaltwo />} />
          <Route path="/forgot" element={<ForgotPassword />} />
          <Route path="/resetPassword" element={<ResetPassword />} />
          <Route element={<PrivateRoutes />}>
            <Route path="/behavior" element={<AdminHome />} />
            <Route path="/savedfeedback" element={<FeedBack />} />
            <Route path="/popularitems" element={<PopularItems />} />
          </Route>
          <Route element={<Redirect />}>
            <Route path="/admin" element={<AdminSignIn />} />
          </Route>
          <Route path="/savefocadot" element={<Savefocadot />} />
          <Route path="/signup" element={<SignUp />} />

        </Routes>
      </BrowserRouter>
      {/* <Animation/> */}
    </>
  );
}

export default App;
