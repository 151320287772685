import React, { useState } from "react";
import axios from "axios";
import { Link, useLocation, useNavigate } from "react-router-dom";
import focadot1 from "../../Images/badge.png";
import focadot from "../../Images/Focadot.png";
import { useDispatch, useSelector } from "react-redux";
import "./Feedback.css";
import { setField3 } from "../Redux/Forcadot";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import { BASEURL } from "../../utils/serverInstance";
function Feedback() {
  const data = useSelector((state) => state.fac);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  let user = useSelector((state) => state?.signupSlice);
  console.log("userId", user);

  const [inputValue, setInputValue] = useState("");
  const [answers, setAnswers] = useState({
    feedbacks: ["", "", "", ""], // Initialize an array to store the feedback values
  });

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };
  const handleYesButtonClick = (index) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      feedbacks: prevAnswers.feedbacks.map((val, i) =>
        i === index ? "Yes" : val
      ),
    }));
  };

  const handleNoButtonClick = (index) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      feedbacks: prevAnswers.feedbacks.map((val, i) =>
        i === index ? "No" : val
      ),
    }));
  };

  const handleSubmit = () => {
    console.log("Answers:", answers);
    console.log("Input value:", inputValue);
  };

  const FeedbackArray = [
    {
      text: "Was it easier to meditate than you thought?*",
      button: "No",
      button1: "Yes",
    },
    {
      text: "Could you see yourself using this app again if it had more features and was more customisable?*",
      button: "No",
      button1: "Yes",
    },
    {
      text: "Would it be worth investing $1 per week in your mindfulness through using a more advanced version of this app?*",
      button: "No",
      button1: "Yes",
    },
  ];

  const handleSubmitData = (event) => {
    const requestData = {
      feedback1: answers.feedbacks[0],
      feedback2: answers.feedbacks[1],
      feedback3: answers.feedbacks[2],
      feedbackComment: inputValue,
      userId: user?.user?._id,
    };
    {
      console.log(requestData, "requestDataww");
    }
    if (
      requestData?.feedback1 == "" ||
      requestData?.feedback2 == "" ||
      requestData?.feedback3 == ""
    ) {
      return Swal.fire("Fill all the fields", "", "error");
    } else {
      axios
        .post(`${BASEURL}/api/createFeedback`, requestData)
        .then((response) => {
          console.log(response, "requestDatassww");
          dispatch(setField3);
          Swal.fire({
            title: response.data.message,
            icon: "success",
            iconColor: "#6f00fe",
            showCloseButton: true,
            showCancelButton: false,
            confirmButtonColor: "#6f00fe",
            confirmButtonText: "OK",
            position: "center",
            customClass: {
              container: "swal-container",
              content: "swal-content",
              title: "swal-title",
              actions: "swal-actions",
            },
            onOpen: () => {
              const swalContent =
                Swal.getPopup().querySelector(".swal-content");
              if (swalContent) {
                swalContent.classList.add("swal-content-custom");
              }
            },
          });
          navigate("/meditation");

          // Handle the response data
          console.log(response.data);
        })
        .catch((error) => {
          Swal.fire(error.message, "error");

          console.error(error);
        });
    }
  };

  return (
    <div>
      <div className="main_boxdiv">
        <div className="fee_box_div">
          {/* #6f00fe portion */}
          <div className="fee_upper_portion">
            <div className="fee_focadot_imgdiv">
              <img src={focadot} />
              <p className="fee_text">
                Wait! Before you leave, we'd really value your feedback!
              </p>
            </div>
          </div>

          {/* lower portion */}
          <div className="fee_lower_div">
            <ol className="fee_ollist">
              {FeedbackArray.map((item, index) => (
                <li className="fee_lower_text" key={index}>
                  {item.text}
                  <div className="buttonsArea">
                    <button
                      className={`fee_no_button ${
                        answers.feedbacks[index] === "No" ? "selected" : ""
                      }`}
                      onClick={() => handleNoButtonClick(index)}
                    >
                      {item.button}
                    </button>
                    <button
                      className={`fee_yes_button ${
                        answers.feedbacks[index] === "Yes" ? "selected" : ""
                      }`}
                      onClick={() => handleYesButtonClick(index)}
                    >
                      {item.button1}
                    </button>
                  </div>
                </li>
              ))}
            </ol>
            <textarea
              className="fee_inputtag"
              placeholder="Type answer here"
              value={inputValue}
              onChange={handleInputChange}
            />

            <button className="fee_next_button" onClick={handleSubmitData}>
              Submit and leave
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Feedback;
