
import signupReducer from "./signupSlice";
import { configureStore, combineReducers } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";

import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";

import signupSlice from "./signupSlice"
import fac from "./Forcadot"
import { ColorSlice } from "./ColorSlice";
const persistConfig = {
  key: "root",
  version: 1,
  storage,
};
const signupReducerPersistance = persistReducer(persistConfig, signupSlice);
const facPersistance = persistReducer(persistConfig, fac);
const colorPersistance = persistReducer(persistConfig, ColorSlice.reducer);
const store = configureStore({
  reducer: {
    signupSlice: signupReducerPersistance,
     fac: facPersistance,
     color: colorPersistance,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
    devTools: process.env.NODE_ENV !== "production", 
});

export default store;