import React, { useState } from "react";
import { Button, Modal } from "antd";
import x from "../../Images/X.png";
import { Link } from "react-router-dom";
import "./ModalOne.css";
function Modalone(props) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [remainingTime, setRemainingTime] = useState(120); // 2 minutes in seconds
  const [clickCount, setClickCount] = useState(0);

  const handleClick = () => {
    setClickCount(clickCount + 1);

    if (clickCount + 1 === 2) {
      // Start the timer
      setInterval(() => {
        setRemainingTime((prevTime) => prevTime - 1);
      }, 1000);
    }
  };

  const formatTime = () => {
    const minutes = Math.floor(remainingTime / 60);
    const seconds = remainingTime % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    // setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div className="main_boxdiv" onClick={handleClick}>
        <div className="modal_box_div">
          {clickCount >= 2 && (
            <div>{remainingTime > 0 ? formatTime() : "Time is up!"}</div>
          )}
          <Button type="primary" onClick={showModal}>
            Open Modal
          </Button>

          <Modal open={isModalOpen}>
            
            <p className="protip">
              <span className="protip_red">PRO TIP!</span>
              <br />
              Double tap the screen background to see yourtime remaining or to
              exit.
            </p>
            <p className="protipsmall">
              But try to avoid checking the time ;-)
            </p>

            <Link className="link_sign" to="/modaltwo">
              <div className="got_itbutton">
                <button onClick={() => handleCancel()} className="got_it">
                  Thanks, got it!
                </button>
              </div>
            </Link>
          </Modal>
        </div>
      </div>
    </>
  );
}

export default Modalone;
