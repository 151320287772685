import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    opacityValue: 50, // Set the initial opacity value here
  };

  export const ColorSlice = createSlice({
    name: "color",
    initialState,
    reducers: {
      setOpacityValue: (state, action) => {
        state.opacityValue = action.payload;
      },
    },
  });

// Action creators are generated for each case reducer function
export const { setOpacityValue } = ColorSlice.actions;

export default ColorSlice.reducer;