import React, { useEffect, useState, useRef } from "react";
import focadot from "../../Images/Focadot.png";
import vector from "../../Images/Vector.png";
import { Link, useLocation } from "react-router-dom";
import { CirclesWithBar } from "react-loader-spinner";
import pic from "../../Images/pic.png";
import pic1 from "../../Images/pic1.png";
import pic2 from "../../Images/pic2.png";
import pic3 from "../../Images/pic3.png";
import one from "../../Images/one.jpg";
import two from "../../Images/two.jpg";
import three from "../../Images/three.jpg";
import four from "../../Images/four.jpg";
import five from "../../Images/five.jpg";
import six from "../../Images/six.jpg";
import seven from "../../Images/seven.jpg";
import eight from "../../Images/eight.jpg";
import nine from "../../Images/nine.jpg";
import ten from "../../Images/ten.jpg";
import eleven from "../../Images/eleven.jpg";
import s1 from "../../Images/sky/s1.jpg";
import s2 from "../../Images/sky/s2.jpg";
import s3 from "../../Images/sky/s3.jpg";
import s4 from "../../Images/sky/s4.jpg";
import s5 from "../../Images/sky/s5.jpg";
//mountain
import m1 from "../../Images/mountain/m1.jpg";
import m2 from "../../Images/mountain/m2.jpg";
import m3 from "../../Images/mountain/m3.jpg";
import m4 from "../../Images/mountain/m4.jpg";
import m5 from "../../Images/mountain/m5.jpg";
import m6 from "../../Images/mountain/m6.jpg";
import m7 from "../../Images/mountain/m7.jpg";
import m8 from "../../Images/mountain/m8.jpg";
import m9 from "../../Images/mountain/m9.jpg";
import m10 from "../../Images/mountain/m10.jpg";
import m11 from "../../Images/mountain/m11.jpg";
import m12 from "../../Images/mountain/m12.jpg";
import m13 from "../../Images/mountain/m13.jpg";
///forest
import f1 from "../../Images/forest/f1.jpg";
import f2 from "../../Images/forest/f2.jpg";
import f3 from "../../Images/forest/f3.jpg";
import f4 from "../../Images/forest/f4.jpg";
import f5 from "../../Images/forest/f5.jpg";
import f6 from "../../Images/forest/f6.jpg";
import f7 from "../../Images/forest/f7.jpg";
import f8 from "../../Images/forest/f8.jpg";
import f9 from "../../Images/forest/f9.jpg";
import f10 from "../../Images/forest/f10.jpg";
import f11 from "../../Images/forest/f11.jpg";
import f12 from "../../Images/forest/f12.jpg";
///desert
import d1 from "../../Images/desert/d1.jpg";
import d2 from "../../Images/desert/d2.jpg";
import d3 from "../../Images/desert/d3.jpg";
import d4 from "../../Images/desert/d4.jpg";
import d5 from "../../Images/desert/d5.jpg";
import d6 from "../../Images/desert/d6.jpg";
import d7 from "../../Images/desert/d7.jpg";
import d8 from "../../Images/desert/d8.jpg";
import d9 from "../../Images/desert/d9.jpg";
import d10 from "../../Images/desert/d10.jpg";
import d11 from "../../Images/desert/d11.jpg";

///beach
import b1 from "../../Images/beach/b1.jpg";
import b2 from "../../Images/beach/b2.jpg";
import b3 from "../../Images/beach/b3.jpg";
import b4 from "../../Images/beach/b4.jpg";
import b5 from "../../Images/beach/b5.jpg";
import b6 from "../../Images/beach/b6.jpg";
import b7 from "../../Images/beach/b7.jpg";
import b8 from "../../Images/beach/b8.jpg";

///
import Slider from "rc-slider";
import "rc-slider/assets/index.css";

import "./Color.css";
import ColorPicker from "@radial-color-picker/react-color-picker";
import "@radial-color-picker/react-color-picker/dist/react-color-picker.min.css";
import { useDispatch, useSelector } from "react-redux";
import { setField3 } from "../Redux/Forcadot";
import { setOpacityValue } from "../Redux/ColorSlice";
const array = [pic, pic1, pic2, pic3];
function Color() {
  const location = useLocation();
  console.log("INDEX", location);
  const [imageLoaded, setImageLoaded] = useState(false);
  const data = useSelector((state) => state?.fac?.picture);
  const pFlow = useSelector((state) => state?.fac?.Flow);
  const imageType = useSelector((state) => state?.fac?.imageType);
  const [opacity, setOpacity] = useState(50);
  const colorState = useSelector((state) => state.color);
  const user = useSelector((state) => state?.signupSlice?.user?._id);
  const onOpacityChange = (value) => {
    setOpacity(value); // Update the opacity value when the slider value changes
    dispatch(setOpacityValue(value));
  };
  console.log("pFlow", pFlow);
  const imageUrl = data ? data : ""; // Set a default value if data is undefined
  const fileName = imageUrl.split("/").pop(); // Get the filename with extension
  const word = fileName.split(".")[0]; // Get the word before the dot
  console.log("word==", word);
  console.log("imageType==", imageType);

  const dispatch = useDispatch();
  const [color, setColor] = React.useState({
    hue: 90,
    saturation: 100,
    luminosity: 50,
    alpha: opacity,
  });

  const onInput = (hue) => {
    setColor((prev) => {
      return {
        ...prev,
        hue,
      };
    });
  };
  const changeColor = (color) => {
    console.log("color==", color);
  };
  let exp = useSelector((state) => state.fac);
  exp = {
    ...exp,
    color: color,
  };

  const handleButtonClick1 = () => {
    dispatch(setField3(exp));
  };
  const marks = {
    0: {
      style: {
        color: "#6f00fe", // Red color
      },
      label: <strong>0%</strong>,
    },
    100: {
      style: {
        color: "#6f00fe", // White color
      },
      label: <strong>100%</strong>,
    },
  };

  const handleStyle = {
    borderColor: "#6f00fe", // Red color
    backgroundColor: "#ffffff", // White color
  };

  const railStyle = {
    backgroundColor: "#6f00fe", // Red color
  };

  const trackStyle = {
    backgroundColor: "#6f00fe", // Red color
  };

  const handle = (props) => {
    const { opacity, dragging, index, ...restProps } = props;

    return (
      <Slider.Handle
        {...restProps}
        style={{ ...handleStyle, left: `${opacity}%` }}
        value={opacity}
        aria-valuemin={0}
        aria-valuemax={100}
        aria-valuenow={opacity}
        role="slider"
      >
        <div className="handle-value">{opacity}%</div>
      </Slider.Handle>
    );
  };

  const circleRef = useRef(null);

  const inhaleDuration = exp.customBreath === "custom" ? 5 : exp.inhaleSec;
  const inhaleHoldDuration =
    exp.customBreath === "custom" ? 5 : exp.inhaleHolSec;
  const exhaleDuration = exp.customBreath === "custom" ? 5 : exp.exhaleSec;
  const exhaleHoldDuration =
    exp.customBreath === "custom" ? 5 : exp.exhalholdSec;
  const d = 1;
  useEffect(() => {
    const circleElement = circleRef.current;

    const animateCircle = () => {
      circleElement.style.transform = "scale(0.1)"; // Set the scale to 0.5 to make it smaller
      // circleElement.style.opacity = "1"; // Reduce the opacity to make it less visible
      circleElement.style.transition = `transform ${d}ms ease-out, opacity ${d}ms ease-out`;

      setTimeout(() => {
        circleElement.style.transform = "scale(1)";
        // circleElement.style.opacity = "1";
        circleElement.style.transition = `transform ${inhaleDuration}s ease-in, opacity ${inhaleDuration}s ease-in`;

        setTimeout(() => {
          circleElement.style.transform = "scale(1)";
          // circleElement.style.opacity = "1";
          circleElement.style.transition = `transform ${inhaleHoldDuration}s ease-in-out, opacity ${inhaleHoldDuration}s ease-in-out`;

          setTimeout(() => {
            circleElement.style.transform = "scale(0.1)";
            // circleElement.style.opacity = "0.5";
            circleElement.style.transition = `transform ${exhaleDuration}s ease-out, opacity ${exhaleDuration}s ease-out`;

            setTimeout(() => {
              circleElement.style.transform = "scale(0.1)";
              // circleElement.style.opacity = "1"; // Set the opacity to 1 to keep it partially visible
              circleElement.style.transition = `transform ${exhaleHoldDuration}s ease-in-out, opacity ${exhaleHoldDuration}s ease-in-out`;

              setTimeout(() => {
                animateCircle();
              }, exhaleHoldDuration * 1000);
            }, exhaleDuration * 1000);
          }, inhaleHoldDuration * 1000);
        }, inhaleDuration * 1000);
      }, d * 100);
    };
    if (imageLoaded) {
      animateCircle();
    }
  }, [imageLoaded]);
  return (
    <div>
      <div className="main_boxdiv">
        <div className="col_box_div">
          {/* #6f00fe portion */}
          <div className="col_portion">
            <div className="col_imgdiv">
              {/* <img src={focadot} /> */}
              <div className="logoWhite"></div>
            </div>

            <div className="col_div">
              <Link className="link_sign" to="/theme">
                <div className="col_out">
                  <img src={vector} />
                </div>
              </Link>
              <div className="col_divs_text">
                <div className="col_text">Colour</div>
              </div>
            </div>
          </div>

          {/* lower portion */}
          <div className="color_lowerdiv">
            <div className="color_lowertext">
              Select the colour and opacity of your Focadot{" "}
            </div>
            <div className="color_picdiv">
              <ColorPicker
                {...color}
                onInput={onInput}
                variant={"persistent"}
                onChange={(c) => changeColor(c)}
              />
            </div>

            <div className="opacity_slider">
              <Slider
                min={0}
                max={100}
                marks={marks}
                step={1}
                handle={handle}
                handleStyle={handleStyle}
                railStyle={railStyle}
                trackStyle={trackStyle}
                value={opacity}
                onChange={onOpacityChange}
              />
              <div style={{ color: "#6f00fe" }}>{opacity}%</div>
            </div>
            <div className="img_bigdiv">
              {!imageLoaded && (
                <div
                  style={{
                    position: "absolute",
                    left: "37%",
                    top: "75px",
                  }}
                >
                  <CirclesWithBar
                    height="100"
                    width="100"
                    color=""
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    outerCircleColor="#6f00fe"
                    innerCircleColor="#6f00fe"
                    barColor="#6f00fe"
                    ariaLabel="circles-with-bar-loading"
                  />
                </div>
              )}
              <img
                className="img_big"
                onLoad={() => setImageLoaded(true)}
                src={
                  word === "one"
                    ? one
                    : word === "two"
                    ? two
                    : word === "three"
                    ? three
                    : word === "four"
                    ? four
                    : word === "five"
                    ? five
                    : word === "six"
                    ? six
                    : word === "seven"
                    ? seven
                    : word === "eight"
                    ? eight
                    : word === "nine"
                    ? nine
                    : word === "ten"
                    ? ten
                    : word === "eleven"
                    ? eleven
                    : word === "m1" && imageType === "mountain"
                    ? m1
                    : word === "m2" && imageType === "mountain"
                    ? m2
                    : word === "m3" && imageType === "mountain"
                    ? m3
                    : word === "m4" && imageType === "mountain"
                    ? m4
                    : word === "m5" && imageType === "mountain"
                    ? m5
                    : word === "m6" && imageType === "mountain"
                    ? m6
                    : word === "m7" && imageType === "mountain"
                    ? m7
                    : word === "m8" && imageType === "mountain"
                    ? m8
                    : word === "m9" && imageType === "mountain"
                    ? m9
                    : word === "m10" && imageType === "mountain"
                    ? m10
                    : word === "m11" && imageType === "mountain"
                    ? m11
                    : word === "m12" && imageType === "mountain"
                    ? m12
                    : word === "m13" && imageType === "mountain"
                    ? m13
                    : word === "m1" && imageType === "forest"
                    ? f1
                    : word === "m2" || (word === "f2" && imageType === "forest")
                    ? f2
                    : word === "m3" || (word === "f3" && imageType === "forest")
                    ? f3
                    : word === "m4" || (word === "f4" && imageType === "forest")
                    ? f4
                    : word === "m5" || (word === "f5" && imageType === "forest")
                    ? f5
                    : word === "m6" || (word === "f6" && imageType === "forest")
                    ? f6
                    : word === "m7" || (word === "f7" && imageType === "forest")
                    ? f7
                    : word === "m8" || (word === "f8" && imageType === "forest")
                    ? f8
                    : word === "m9" || (word === "f9" && imageType === "forest")
                    ? f9
                    : word === "m10" ||
                      (word === "f10" && imageType === "forest")
                    ? f10
                    : word === "m11" ||
                      (word === "f11" && imageType === "forest")
                    ? f11
                    : word === "m12" ||
                      (word === "f12" && imageType === "forest")
                    ? f12
                    : word === "m1" && imageType === "desert"
                    ? d1
                    : word === "m2" || (word === "d2" && imageType === "desert")
                    ? d2
                    : word === "m3" || (word === "d3" && imageType === "desert")
                    ? d3
                    : word === "m4" || (word === "d4" && imageType === "desert")
                    ? d4
                    : word === "m5" || (word === "d5" && imageType === "desert")
                    ? d5
                    : word === "m6" || (word === "d6" && imageType === "desert")
                    ? d6
                    : word === "m7" || (word === "d7" && imageType === "desert")
                    ? d7
                    : word === "m8" || (word === "d8" && imageType === "desert")
                    ? d8
                    : word === "m9" || (word === "d9" && imageType === "desert")
                    ? d9
                    : word === "m10" ||
                      (word === "d10" && imageType === "desert")
                    ? d10
                    : word === "m11" ||
                      (word === "d11" && imageType === "desert")
                    ? d11
                    : word === "m1" && imageType === "beach"
                    ? b1
                    : word === "m2" || (word === "b2" && imageType === "beach")
                    ? b2
                    : word === "m3" || (word === "b3" && imageType === "beach")
                    ? b3
                    : word === "m4" || (word === "b4" && imageType === "beach")
                    ? b4
                    : word === "m5" || (word === "b5" && imageType === "beach")
                    ? b5
                    : word === "m6" || (word === "b6" && imageType === "beach")
                    ? b6
                    : word === "m7" || (word === "b7" && imageType === "beach")
                    ? b7
                    : word === "m8" || (word === "b8" && imageType === "beach")
                    ? b8
                    : word === "m1" && imageType === "sky"
                    ? s1
                    : word === "m2" || (word === "s2" && imageType === "sky")
                    ? s2
                    : word === "m3" || (word === "s3" && imageType === "sky")
                    ? s3
                    : word === "m4" || (word === "s4" && imageType === "sky")
                    ? s4
                    : word === "m5" || (word === "s5" && imageType === "sky")
                    ? s5
                    : ""
                }
              />
              <div>
                {imageLoaded ? (
                  <div
                    style={{
                      backgroundColor: `hsla(${color.hue}, ${
                        color.saturation
                      }%, ${color.luminosity}%, ${opacity / 100})`,
                    }}
                    ref={circleRef}
                    className="img_frame"
                  ></div>
                ) : (
                  ""
                )}
                <div className="img_frame1">
                  {/* <div
                  
                  className="img_roundup"
                >
                </div> */}
                </div>
              </div>
            </div>

            <div className="nxt_buttondiv">
              <Link
                to={
                  pFlow === "Flow2" || user
                    ? "/animate"
                    : pFlow === "Flow1"
                    ? "/almost"
                    : user
                }
                className="link_sign"
              >
                {" "}
                <button
                  className="col_next_button"
                  onClick={handleButtonClick1}
                >
                  Next
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Color;
