import React from 'react'
import './Admin.css'
import UsersBehaviour from './UsersBehaviour'
import PopularCategory from './PopularCategory'
import AdminNavbar from './AdminNavbar'

const AdminHome = () => {
    return (
        <>
        <div className="homeMain">
            <div className='navHomeOuter'><AdminNavbar /></div>
            <div className='adminMian'>
                <div className="BehaviourArea">
                    <div className="UseText">Users behaviour:</div>
                    <div className="tablebehav">
                        <UsersBehaviour />
                    </div>
                </div>
            </div>
        </div>

        </>
    )
}

export default AdminHome
