import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import { persistReducer } from "redux-persist";

import { useDispatch, useSelector } from "react-redux";
import storage from "redux-persist/lib/storage";
import { BASEURL } from "../../utils/serverInstance";

export const signup = createAsyncThunk("signup/signup", async (formData) => {
  try {
    const response = await axios.post(
      `${BASEURL}/api/registerCustomer`,
      formData
    );
    console.log("responseuser2", response.data);
    // Swal.fire(response.data.message, "success");
    if (response) {
      // window.location.href = "/sign"; //
    }
    return response.data;
  } catch (error) {
    // Swal.fire(error.response.data.message, "error");
    console.log("11111111", error);
    throw new Error(error.message);
  }
});

export const login = createAsyncThunk("signup/login", async (formData) => {
  try {
    const response = await axios.post(`${BASEURL}/api/loginCustomer`, formData);
    return response.data.user;
  } catch (error) {
    throw new Error(error.message);
  }
});

// Add a logout action
export const logout = createAsyncThunk("signup/logout", async () => {
  // ...any additional logic you need for logging out...
});

// export const updateFeedbackToken = (updatedToken) => (dispatch) => {
//   dispatch({ type: 'UPDATE_FEEDBACK_TOKEN', payload: updatedToken });
// };

const signupSlice = createSlice({
  name: "signup",
  initialState: {
    isLoading: false,
    error: null,
    user: {
      FeedabkToken: false
    }, // Add a user field to store the user data
  },
  reducers: {
    updateFeedbackToken: (state, action) => {
      return {
        ...state,
        user: {
          ...state.user,
          FeedabkToken: action.payload,
        },
      };
    },

    setUser: (state, action) => {
      state.user = action.payload;
    },

  }, extraReducers: (builder) => {
    builder
      .addCase(signup.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(signup.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(signup.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(login.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload; // Set the user data in the state
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      // Add a case for logout
      .addCase(logout.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(logout.fulfilled, (state) => {
        state.isLoading = false;
        state.user = null; // Set the user to null
      })
      .addCase(logout.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["signup"],
};

export const { updateFeedbackToken,setUser } = signupSlice.actions; // Export the action creator


const signupR = signupSlice.reducer;

export default persistReducer(persistConfig, signupR);
