import React, { useState } from "react";
import { Button, Modal } from "antd";
import "./ModalOne.css";
function Modaltwo(props) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    // setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div className="main_boxdiv">
        <div className="modal_box_div">
          <Button type="primary" onClick={showModal}>
            Open ModalTwo
          </Button>
          <Modal open={isModalOpen}>
            <p className="protip protip_two">
              <span className="protip_red">PRO TIP!</span>
              <br />
              Play some music through your Spotify or YoutubePremium if you
              want!
            </p>
            <p className="protipsmall">
              We will build this into a later version of the app.
            </p>

            <div className="got_itbutton">
              <button onClick={() => handleCancel()} className="got_it">
                Thanks, got it!
              </button>
            </div>
          </Modal>
        </div>
      </div>
    </>
  );
}

export default Modaltwo;
