import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import './FeedBack.css'
import '.././Admin.css'
import { BASEURL } from '../../../utils/serverInstance';
import ReactPaginate from 'react-paginate';
import { IoIosArrowBack } from 'react-icons/io';
import { IoIosArrowForward } from 'react-icons/io';
import Pagination from '@mui/material/Pagination'; 




const FeedBackTable = () => {
    const [data, setData] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);
    const rowsPerPage = 15;

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await axios.get(`${BASEURL}/api/allfeedback`);
            setData(response.data);
            console.log(response, "datafeed")
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const findUserById = (userId) => {
        for (const user of data) {
            const foundUser = user.users.find((user) => user._id === userId);
            if (foundUser) {
                return foundUser;
            }
        }
        return {}; // Return an empty object if user not found
    };
    const handleRowClick = (index) => {
        // Update the selected row's index based on the click event
        setSelectedRow((prevSelectedRow) => (prevSelectedRow === index ? null : index));
    };


    const offset = currentPage * rowsPerPage;
    const displayedData = data?.slice(offset, offset + rowsPerPage);
    const pageCount = Math.ceil(data?.length / rowsPerPage);

    const handlePreviousClick = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextClick = () => {
        if (currentPage < pageCount - 1) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePageNumberClick = (selectedPage) => {
        setCurrentPage(selectedPage);
    };
    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage - 1);
    };
    // const generatePageNumbers = () => {
    //     const pageNumbers = [];

    //     // Always show the first page
    //     pageNumbers.push(1);

    //     // Show 3 pages before the active page and 2 pages after
    //     for (let i = currentPage - 3; i <= currentPage + 2; i++) {
    //         if (i > 1 && i < pageCount) {
    //             pageNumbers.push(i);
    //         }
    //     }

    //     // Add ellipsis if there are more than 4 pages between 1 and the current page
    //     if (currentPage - 4 > 1) {
    //         pageNumbers.push('...');
    //     }

    //     // Add ellipsis if there are more than 4 pages between the current page and the last page
    //     if (pageCount - currentPage > 4) {
    //         pageNumbers.push('...');
    //     }

    //     // Always show the last page
    //     pageNumbers.push(pageCount);

    //     return pageNumbers;
    // };

    return (
        <>
            <div className='lowerfeedTable'>
                <TableContainer component={Paper} >
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                            <TableCell className="headingArea">Sr No</TableCell>
                                <TableCell className="headingArea">User Name</TableCell>
                                <TableCell className="headingArea">User Email</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {displayedData?.map((user, outerIndex) => (
                                <React.Fragment key={outerIndex}>
                                    <TableRow
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        onClick={() => handleRowClick(outerIndex)}
                                        style={{ cursor: "pointer" }}
                                    >
                                        <TableCell className="tableDetail">{currentPage * rowsPerPage + outerIndex + 1}</TableCell>
                                        <TableCell className="tableDetail">{user[0]?.userId?.fName}</TableCell>
                                        <TableCell className="tableDetail">{user[0]?.userId?.email}</TableCell>
                                    </TableRow>
                                    {selectedRow === outerIndex && (
                                        <TableRow
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell colSpan={5}>
                                                <TableContainer component={Paper}>
                                                    <Table sx={{ minWidth: 650 }} aria-label="expanded table">
                                                        <TableHead>
                                                            <TableRow style={{ borderBottom: "none" }}>
                                                                <TableCell style={{ borderBottom: "none" }} className="headingArea">Sr No.</TableCell>
                                                                <TableCell style={{ borderBottom: "none" }} className="headingArea">Easier/Not</TableCell>
                                                                <TableCell style={{ borderBottom: "none" }} className="headingArea">Use Again</TableCell>
                                                                <TableCell style={{ borderBottom: "none" }} className="headingArea">Worth/Not</TableCell>
                                                                <TableCell style={{ borderBottom: "none" }} className="headingArea">FeedBack</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {user.map((feedback, innerIndex) => (
                                                                <TableRow key={innerIndex}>
                                                                    <TableCell style={{ borderBottom: "none" }} className="tableDetail">{innerIndex + 1}</TableCell>
                                                                    <TableCell style={{ borderBottom: "none" }} className="tableDetail">{feedback?.feedback1}</TableCell>
                                                                    <TableCell style={{ borderBottom: "none" }} className="tableDetail">{feedback?.feedback2}</TableCell>
                                                                    <TableCell style={{ borderBottom: "none" }} className="tableDetail">{feedback?.feedback3}</TableCell>
                                                                    <TableCell style={{ borderBottom: "none" }} className="tableDetail">{feedback?.feedbackComment}</TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </React.Fragment>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <div className='custom-pagination'>
                {/* <button onClick={handlePreviousClick} disabled={currentPage === 0} className='prevBtn'><IoIosArrowBack /></button> */}
                <Pagination
                    count={Math.ceil(data?.length / rowsPerPage)}
                    page={currentPage + 1}
                    onChange={handleChangePage}
                    variant="outlined"
                    shape="rounded"
                    color="primary"
                />
                {/* <button onClick={handleNextClick} disabled={currentPage === pageCount - 1} className='prevBtn'><IoIosArrowForward /></button> */}
            </div>
        </>

    );

}

export default FeedBackTable
