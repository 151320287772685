import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";

import storage from "redux-persist/lib/storage";
const initialStateFunction = () => ({
  experience: "",
  durationn: "",
  picture: "",
  imageType:"",
  color: "",
  inhaleSec: "",
  inhaleHolSec: "",
  exhaleSec: "",
  exhalholdSec: "",
  custom: "",
  customBreath: "",
  Flow: "",
  meditatioTime:""
});
export const facReducer = createSlice({
  name: "fac",
  initialState: initialStateFunction(),
  reducers: {
    setField3(state, action) {
      console.log(action.payload);
      return action.payload;
    },

    resetState: (state) => initialStateFunction(),
  },
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["facReducer"],
};

const fac = facReducer.reducer;

// Action creators are generated for each case reducer function
export const { setField3 } = facReducer.actions;
export default persistReducer(persistConfig, fac);
