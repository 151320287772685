import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { BASEURL } from '../../../utils/serverInstance';
import './FeedBack.css'
import FeedBackTable from './FeedBackTable'
import AdminNavbar from '../AdminNavbar'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


const FeedBack = () => {
  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${BASEURL}/api/feedbackstats`);
      setData(response.data);
      console.log(response.data, "atafeednumberdf")
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  useEffect(() => {
    fetchData();
  }, []);

  const FeedbackArray = [
    {
      text: "Was it easier to meditate than you thought?",
    },
    {
      text: "Could you see yourself using this app again if it had more features and was more customisable?",
    },
    {
      text: "Would it be worth investing $1 per week in your mindfulness through using a more advanced version of this app?",
    },
  ];
  const mapFeedbackWithQuestions = (FeedbackArray, data) => {
    const mappedData = FeedbackArray.map((questionObj) => {
      const question = questionObj.text;
      const feedbackData = data[`feedback${FeedbackArray.indexOf(questionObj) + 1}`];
      return { question, ...feedbackData };
    });
    return mappedData;
  };

  const mappedData = mapFeedbackWithQuestions(FeedbackArray, data);
  const totalFeedbacks = mappedData.map((feedback) => feedback.Yes + feedback.No);
  const maxTotal = Math.max(...totalFeedbacks);
  return (
    <>
      <div className="feedOuter">
        <div className="navOuter">
          <AdminNavbar />
        </div>
        <div className='feedMain'>
          <div className="feedinner">
            <div className='totalArea'>
              <div className='feedText'>Total FeedBacks:</div>
              <div className='feedText1'>({maxTotal})</div>
            </div>
            <div className='feedTableArea1'>
              <TableContainer component={Paper} >
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell className="headingAreaQu">Question</TableCell>
                      <TableCell className="headingAreaQu">Yes</TableCell>
                      <TableCell className="headingAreaQu">No</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {mappedData.map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell className="quIN">{row.question}</TableCell>
                        <TableCell  className="quIN">{row.Yes}</TableCell>
                        <TableCell className="quIN">{row.No}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
          <div className="feedinner">
            <div className="feedText">Feedbacks:</div>
            <div className='feedTableArea2'>
              <FeedBackTable />
            </div>
          </div>
        </div>
      </div>

    </>

  )
}

export default FeedBack

