import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../Redux/signupSlice";
import logo from "../../Images/logo1.png";
import Form from "react-bootstrap/Form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import "../SignIn/SignIn.css";
// import withReactContent from "sweetalert2-react-content";
import axios from "axios";
import { BASEURL } from "../../utils/serverInstance";
import focadot from "../../Images/badge.png";

function ForgotPassword() {
  const dispatch = useDispatch();
  let exp = useSelector((state) => state.fac.Flow);
  // const MySwal = withReactContent(Swal);

  // Define local state
  const [formData, setFormData] = useState({
    email: "",
  });
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.email) {
      Swal.fire({
        title: "Please enter valid Email",
        icon: "error",
        confirmButtonColor: "#6f00fe", // Background color
        confirmButtonTextColor: "#FFFFFF", // Text color
      });
    } else {
      axios
        .post(`${BASEURL}/api/forgot`, formData)
        .then((response) => {
          Swal.fire({
            title: response.data.message,
            icon: "success",
            iconColor: "#6f00fe",
            showCloseButton: true,
            showCancelButton: false,
            confirmButtonColor: "#6f00fe",
            confirmButtonText: "OK",
            position: "center",
            customClass: {
              container: "swal-container",
              content: "swal-content",
              title: "swal-title",
              actions: "swal-actions",
            },
            onOpen: () => {
              const swalContent =
                Swal.getPopup().querySelector(".swal-content");
              if (swalContent) {
                swalContent.classList.add("swal-content-custom");
              }
            },
          }).then((result) => {
            if (result.isConfirmed) {
              // Perform your navigation here
              navigate("/sign");
            }
          });
        })
        .catch((error) => {
          Swal.fire({
            title: error.response.data.message,
            showConfirmButton: true,
            confirmButtonColor: "#6f00fe",
            confirmButtonText: '<span style="color: white">OK</span>',
          });
          console.error(error);
        });
    }
  };
  return (
    <div>
      <div className="main_boxdiv">
        <div className="sign_box_div">
          {/* #6f00fe portion */}
          <div className="sign_portion">
            <div className="sign_div">
              <span className="sign_text">Focadot</span>
              {/* <img className="sign_logo" src={logo} /> */}
              <div className="sign_logo"></div>
            </div>
          </div>

          {/* lower portion */}
          <div className="sign_lowerdiv">
            <p className="sign_wel">Forgot Password!</p>

            <Form onSubmit={handleSubmit}>
              <Form.Control
                type="email"
                placeholder="Email"
                className="sign_fname sign_bot"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />

              <button type="submit" className="sign_button">
                Submit
              </button>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
