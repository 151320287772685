import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useLocation, useNavigate } from "react-router-dom";
import focadot from "../../Images/Focadot.png";
import styles from "./Save.module.css";
import { useDispatch, useSelector } from "react-redux";
import { setField3 } from "../Redux/Forcadot";
import Modal from "react-bootstrap/Modal";
import Swal from "sweetalert2";
import { logout } from "../Redux/signupSlice";
import { BASEURL } from "../../utils/serverInstance";
import { updateFeedbackToken } from "../Redux/signupSlice";

function FeedBackModal(props) {
  const data = useSelector((state) => state.fac);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  let user1 = useSelector((state) => state?.signupSlice);
  let user = useSelector((state) => state?.signupSlice?.user);

  console.log("userId", user);

  const [inputValue, setInputValue] = useState("");
  const [answers, setAnswers] = useState({
    feedbacks: ["", "", "", ""], // Initialize an array to store the feedback values
  });

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };
  const handleYesButtonClick = (index) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      feedbacks: prevAnswers.feedbacks.map((val, i) =>
        i === index ? "Yes" : val
      ),
    }));
  };

  const handleNoButtonClick = (index) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      feedbacks: prevAnswers.feedbacks.map((val, i) =>
        i === index ? "No" : val
      ),
    }));
  };
  const handleModalClose = (event) => {
    props.onModalClose(); // Call the function passed from Meditation component
  };
  const handleSubmit = () => {
    console.log("Answers:", answers);
    console.log("Input value:", inputValue);
  };

  const FeedbackArray = [
    {
      text: "Was it easier to meditate than you thought?*",
      button: "No",
      button1: "Yes",
    },
    {
      text: "Could you see yourself using this app again if it had more features and was more customisable?*",
      button: "No",
      button1: "Yes",
    },
    {
      text: "Would it be worth investing $1 per week in your mindfulness through using a more advanced version of this app?*",
      button: "No",
      button1: "Yes",
    },
  ];

  const handleSubmitData = (event) => {
    const requestData = {
      feedback1: answers.feedbacks[0],
      feedback2: answers.feedbacks[1],
      feedback3: answers.feedbacks[2],
      feedbackComment: inputValue,
      userId: user1?.user?._id,
    };
    {
      console.log(requestData, "requestDataww");
    }
    if (
      requestData?.feedback1 == "" ||
      requestData?.feedback2 == "" ||
      requestData?.feedback3 == ""
    ) {
      return Swal.fire("Fill all the fields", "", "error");
    }
    axios
      .post(`${BASEURL}/api/createFeedback`, requestData)
      .then((response) => {
        console.log(response, "requestDatasswwww");

        dispatch(updateFeedbackToken(false));
        dispatch(setField3);
        Swal.fire({
          title: response.data.message,
          icon: "success",
          iconColor: "#6f00fe",
          showCloseButton: true,
          showCancelButton: false,
          confirmButtonColor: "#6f00fe",
          confirmButtonText: "OK",
          position: "center",
          customClass: {
            container: "swal-container",
            content: "swal-content",
            title: "swal-title",
            actions: "swal-actions",
          },
          onOpen: () => {
            const swalContent = Swal.getPopup().querySelector(".swal-content");
            if (swalContent) {
              swalContent.classList.add("swal-content-custom");
            }
          },
        });

        props.onModalClose();
      })
      .catch((error) => {
        Swal.fire(error.message, "error");

        console.error(error);
      });
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="headerPad">
        <div className="fee_upper_portion">
          <div className="fee_focadot_imgdiv">
            {/* <img src={focadot} /> */}
            <div className="logoWhite"></div>
            <p className="fee_text">
              Wait! Before you leave, we'd really value your feedback!
            </p>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body className="headerPad">
        <div className="fee_lower_div">
          <ol className="fee_ollist">
            {FeedbackArray.map((item, index) => (
              <li className="fee_lower_text" key={index}>
                {item.text}
                <div className="buttonsArea">
                  <button
                    className={`fee_no_button ${
                      answers.feedbacks[index] === "No" ? "selected" : ""
                    }`}
                    onClick={() => handleNoButtonClick(index)}
                  >
                    {item.button}
                  </button>
                  <button
                    className={`fee_yes_button ${
                      answers.feedbacks[index] === "Yes" ? "selected" : ""
                    }`}
                    onClick={() => handleYesButtonClick(index)}
                  >
                    {item.button1}
                  </button>
                </div>
              </li>
            ))}
          </ol>
          <div className="textArea">
            <div className="fourArea">
              <div className="fee_lower_text1">4.</div>
              <div className="fee_lower_text2">
                Any other feedback you’d like to share that could help us?
              </div>
            </div>
            <textarea
              className="fee_inputtag"
              placeholder="Type answer here"
              value={inputValue}
              onChange={handleInputChange}
            />
          </div>
          <button className="fee_next_button" onClick={handleSubmitData}>
            Submit and leave
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

const Savefocadot = () => {
  const data = useSelector((state) => state.fac);
  const [modalShow, setModalShow] = React.useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  let user1 = useSelector((state) => state?.signupSlice);
  let user = useSelector((state) => state?.signupSlice?.user);
  const feedbackTriger = useSelector(
    (state) => state?.signupSlice?.user?.FeedabkToken
  );
  const count = useSelector((state) => state?.signupSlice?.user?.count);
  console.log(count, "count");

  useEffect(() => {
    console.log(feedbackTriger, "aggregationResult.lengthddsasdadsa ");

    if (feedbackTriger && (count === 1 || count % 4 === 0)) {
      setModalShow(true);
    }
  }, [feedbackTriger, count]);

  console.log("userId", data);
  const experience = data.experience;
  const duration = data.durationn;
  const inhale = data.inhaleSec;
  const inhaleHold = data.inhaleHolSec;
  const exhale = data.exhaleSec;
  const exhaleHold = data.exhalholdSec;
  const selectedImage = data.picture;
  const color = data.color;
  const meditatioTimeredux = data.meditatioTime;
  const opacityValue = useSelector((state) => state.color.opacityValue); // Get the opacity value from the ColorSlice reducer

  const handleSubmitData = (event) => {
    const requestData = {
      userId: user1?.user?._id,
      experience: experience,
      durationn: duration,
      inhaleSec: inhale,
      inhaleHolSec: inhaleHold,
      exhaleSec: exhale,
      exhalholdSec: exhaleHold,
      picture: selectedImage,
      hue: color.hue,
      saturation: color.saturation,
      luminosity: color.luminosity,
      alpha: color.alpha,
      customBreath: data.customBreath,
      Flow: data?.Flow,
      imageType: data?.imageType,
      opacity: opacityValue,
      meditatioTime:
        meditatioTimeredux === "" || meditatioTimeredux === undefined
          ? duration
          : meditatioTimeredux,
    };
    console.log(requestData, "requestData");
    axios
      .post(`${BASEURL}/api/focadots`, requestData)
      .then((response) => {
        dispatch(setField3);
        Swal.fire({
          title: response.data.message,
          icon: "success",
          iconColor: "#6f00fe",
          showCloseButton: true,
          showCancelButton: false,
          confirmButtonColor: "#6f00fe",
          confirmButtonText: "OK",
          position: "center",
          customClass: {
            container: "swal-container",
            content: "swal-content",
            title: "swal-title",
            actions: "swal-actions",
          },
          onOpen: () => {
            const swalContent = Swal.getPopup().querySelector(".swal-content");
            if (swalContent) {
              swalContent.classList.add("swal-content-custom");
            }
          },
        });
        navigate("/back");

        // Handle the response data
        console.log(response, "responsedata33");
      })
      .catch((error) => {
        Swal.fire(error.message, "error");

        console.error(error);
      });
  };

  return (
    <>
      <FeedBackModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        onModalClose={() => setModalShow(false)}
      />
      <div>
        <div className={styles.main_boxdiv}>
          <div className={styles.box_div}>
            {/* #6f00fe portion */}
            <div className={styles.uper_portion}>
              <div className={styles.focadot_imgdiv1st}>
                {/* <img src={focadot} /> */}
                <div className={styles.logoWhite}></div>
                <div className={styles.text_head}>Save Meditation</div>
              </div>
            </div>

            {/* button section */}

            <div>
              <div className={styles.text_div}>
                <p className={styles.p_text}>
                  Would you like to save your meditation experience?
                </p>
                <div className={styles.buttonsArea}>
                  <button className={styles.saveBtn} onClick={handleSubmitData}>
                    Save
                  </button>
                  <Link to="/back" className={styles.saveBtn1}>
                    No thanks
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Savefocadot;
