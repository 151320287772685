import React from 'react'
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Link, useLocation } from "react-router-dom";
import './Admin.css'

const AdminNavbar = () => {
    return (
        <>
            {['lg'].map((expand) => (
                <Navbar key={expand} expand={expand} className="navSide">
                    <Container fluid className='centerNav' >
                        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} className="togl">
                            <div className="line1"></div>
                            <div className="line2"></div>
                            <div className="line3"></div>
                        </Navbar.Toggle>
                        <Navbar.Offcanvas
                            id={`offcanvasNavbar-expand-${expand}`}
                            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                            placement="start"
                            className="offcan"
                        >
                            <Offcanvas.Header closeButton>
                                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                                    <div className='vanDESIGN'>Focadot</div> 
                                </Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                <Nav className="justify-content-center flex-grow-1 ">
                                    <div className='navsArea'>
                                        <Link to="/behavior" className="vanDESIGN">Behaviours</Link>
                                        <Link to="/savedfeedback" className="vanDESIGN">FeedBack</Link>
                                        <Link to="/popularitems" className="vanDESIGN">Popular Items</Link>
                                        <Link to="/admin" className="vanDESIGN" ><span onClick={()=>{
                                            sessionStorage.clear("token")
                                        }}>Log out</span></Link>
                                    </div>
                                </Nav>
                            </Offcanvas.Body>
                        </Navbar.Offcanvas>
                    </Container>
                </Navbar>
            ))}
        </>
    );
}

export default AdminNavbar
