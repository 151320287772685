import React, { useState } from "react";
import focadot from "../../Images/Focadot.png";
import vector from "../../Images/Vector.png";
import { Link, useNavigate } from "react-router-dom";
import "./Breath.css";
import { useDispatch, useSelector } from "react-redux";
import { setField3 } from "../Redux/Forcadot";
import Swal from "sweetalert2";
function Breath() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [fieldValue1, setFieldValue1] = useState();
  const [fieldValue2, setFieldValue2] = useState();
  const [fieldValue3, setFieldValue3] = useState();
  const [fieldValue4, setFieldValue4] = useState();
  const [currentClassName, setCurrentClassName] = useState("bre_button chose");

  const [custom, setCustom] = useState("");
  console.log("custom", custom);

  const handleButtonClick = () => {
    setCustom("custom");
  };
  const handleInputChange = (fieldName, value) => {
    // Update the corresponding state variable based on the field name
    switch (fieldName) {
      case "field1":
        setFieldValue1(value);
        break;
      case "field2":
        setFieldValue2(value);
        break;
      case "field3":
        setFieldValue3(value);
        break;
      case "field4":
        setFieldValue4(value);
        break;
      default:
        break;
    }
    setCustom("");
    setCurrentClassName("bre_button chose");
  };
  const className = `${currentClassName} ${
    custom === "custom" ? "active" : ""
  }`;
  let exp = useSelector((state) => state.fac);
  exp = {
    ...exp,
    inhaleSec: fieldValue1,
    inhaleHolSec: fieldValue2,
    exhaleSec: fieldValue3,
    exhalholdSec: fieldValue4,
    customBreath: custom,
  };

  const handleNext = () => {
    if (
      (!fieldValue1 || !fieldValue2 || !fieldValue3 || !fieldValue4) &&
      !custom &&
      (!fieldValue1 || !fieldValue2 || !fieldValue3 || !fieldValue4)
    ) {
      Swal.fire({
        icon: "error",
        title: "Warning",
        text: "Please fill all 4 fields or select choose for me",
        confirmButtonColor: "#6f00fe",
        confirmButtonText: "OK",
      });
    } else if (
      custom &&
      !fieldValue1 &&
      !fieldValue2 &&
      !fieldValue3 &&
      !fieldValue4
    ) {
      navigate("/theme");
      dispatch(setField3(exp));
    } else if (
      (fieldValue1 || fieldValue2 || fieldValue3 || fieldValue4) &&
      !custom
    ) {
      navigate("/theme");
      dispatch(setField3(exp));
    } else if (
      (fieldValue1 || fieldValue2 || fieldValue3 || fieldValue4) &&
      custom
    ) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "You can't select both scenarios. Enter custom or 'Choose for me'",
        confirmButtonColor: "#6f00fe",
        confirmButtonText: "OK",
      });
    }
  };

  return (
    <div>
      <div className="main_boxdiv">
        <div className="breath_box_div">
          {/* #6f00fe portion */}
          <div className="upper_portion">
            <div className="focadot_imgdiv">
              {/* <img src={focadot} /> */}
              <div className="logoWhite"></div>
            </div>

            <div className="vector_div">
              <Link className="link_sign" to="/duration">
                <div className="vector_out">
                  <img src={vector} />
                </div>
              </Link>
              <div className="bre_divs_text">
                <div className="bre_text">Breath Timer</div>
              </div>
            </div>
          </div>

          {/* button section */}

          <div>
            <div className="bre_text_div">
              <p className="bre_p_text">Choose your breathing rhythm</p>

              <input
                className="bre_button"
                placeholder="Enter Inhale Time in Sec"
                type="number"
                name="field1"
                onChange={(e) => handleInputChange("field1", e.target.value)}
              />
              <input
                className="bre_button"
                placeholder="Enter Inhale Hold Time in Sec"
                type="number"
                name="field2"
                onChange={(e) => handleInputChange("field2", e.target.value)}
              />
              <input
                className="bre_button exle"
                placeholder="Enter Exhale Time in Sec"
                type="number"
                name="field3"
                onChange={(e) => handleInputChange("field3", e.target.value)}
              />
              <input
                className="bre_button exhold"
                placeholder="Enter Exhale Hold Time in Sec"
                type="number"
                name="field4"
                onChange={(e) => handleInputChange("field4", e.target.value)}
              />
              <button
                className={className}
                type="number"
                onClick={handleButtonClick}
              >
                Choose for me
              </button>

              <div className="button-container">
                <div className="link_sign">
                  <button className="bre_next_button" onClick={handleNext}>
                    Next
                  </button>
                </div>
              </div>

              {/* <button className= {active === "1" ? "dur_button" : undefined} key={1} id="1" onClick={handleClick}>1 minute</button>
    <button className={active === "2" ? "dur_button" : undefined} key={2} id="2" onClick={handleClick}>3 minutes</button>
    <button className={active === "2" ? "dur_button five_minte" : undefined} key={3} id="3" onClick={handleClick}>5 minutes</button>
    <button className={active === "2" ? "dur_button five_minte" : undefined} key={4} id="4" onClick={handleClick}>10 minutes</button>
    <button className={active === "2" ? "dur_button d_custom" : undefined} key={5} id="5" onClick={handleClick}>Custom</button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Breath;
