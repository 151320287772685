import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useInView } from "react-intersection-observer";
import { Button, Modal } from "antd";
import { FullscreenOutlined } from "@ant-design/icons";
import { setField3 } from "../Redux/Forcadot";
import axios from "axios";
import pic from "../../Images/pic.png";
import pic1 from "../../Images/pic1.png";
import pic2 from "../../Images/pic2.png";
import pic3 from "../../Images/pic3.png";
import ColorPicker from "@radial-color-picker/react-color-picker";
import "@radial-color-picker/react-color-picker/dist/react-color-picker.min.css";
import one from "../../Images/one.jpg";
import two from "../../Images/two.jpg";
import three from "../../Images/three.jpg";
import four from "../../Images/four.jpg";
import five from "../../Images/five.jpg";
import six from "../../Images/six.jpg";
import seven from "../../Images/seven.jpg";
import eight from "../../Images/eight.jpg";
import nine from "../../Images/nine.jpg";
import ten from "../../Images/ten.jpg";
import eleven from "../../Images/eleven.jpg";
import s1 from "../../Images/sky/s1.jpg";
import s2 from "../../Images/sky/s2.jpg";
import s3 from "../../Images/sky/s3.jpg";
import s4 from "../../Images/sky/s4.jpg";
import s5 from "../../Images/sky/s5.jpg";
//mountain
import m1 from "../../Images/mountain/m1.jpg";
import m2 from "../../Images/mountain/m2.jpg";
import m3 from "../../Images/mountain/m3.jpg";
import m4 from "../../Images/mountain/m4.jpg";
import m5 from "../../Images/mountain/m5.jpg";
import m6 from "../../Images/mountain/m6.jpg";
import m7 from "../../Images/mountain/m7.jpg";
import m8 from "../../Images/mountain/m8.jpg";
import m9 from "../../Images/mountain/m9.jpg";
import m10 from "../../Images/mountain/m10.jpg";
import m11 from "../../Images/mountain/m11.jpg";
import m12 from "../../Images/mountain/m12.jpg";
import m13 from "../../Images/mountain/m13.jpg";
///forest
import f1 from "../../Images/forest/f1.jpg";
import f2 from "../../Images/forest/f2.jpg";
import f3 from "../../Images/forest/f3.jpg";
import f4 from "../../Images/forest/f4.jpg";
import f5 from "../../Images/forest/f5.jpg";
import f6 from "../../Images/forest/f6.jpg";
import f7 from "../../Images/forest/f7.jpg";
import f8 from "../../Images/forest/f8.jpg";
import f9 from "../../Images/forest/f9.jpg";
import f10 from "../../Images/forest/f10.jpg";
import f11 from "../../Images/forest/f11.jpg";
import f12 from "../../Images/forest/f12.jpg";
///desert
import d1 from "../../Images/desert/d1.jpg";
import d2 from "../../Images/desert/d2.jpg";
import d3 from "../../Images/desert/d3.jpg";
import d4 from "../../Images/desert/d4.jpg";
import d5 from "../../Images/desert/d5.jpg";
import d6 from "../../Images/desert/d6.jpg";
import d7 from "../../Images/desert/d7.jpg";
import d8 from "../../Images/desert/d8.jpg";
import d9 from "../../Images/desert/d9.jpg";
import d10 from "../../Images/desert/d10.jpg";
import d11 from "../../Images/desert/d11.jpg";

///beach
import b1 from "../../Images/beach/b1.jpg";
import b2 from "../../Images/beach/b2.jpg";
import b3 from "../../Images/beach/b3.jpg";
import b4 from "../../Images/beach/b4.jpg";
import b5 from "../../Images/beach/b5.jpg";
import b6 from "../../Images/beach/b6.jpg";
import b7 from "../../Images/beach/b7.jpg";
import b8 from "../../Images/beach/b8.jpg";

///
import x from "../../Images/X.png";
import "./Animate.css";
import { BASEURL } from "../../utils/serverInstance";
import Swal from "sweetalert2";
import tool1 from "../../Images/Tool1.jpeg";
import tool2 from "../../Images/Tool2.jpeg";
import tool3 from "../../Images/Tool3.jpeg";

const array = [pic, pic1, pic2, pic3];

function Animate() {
  const durationn = useSelector((state) => state.fac.durationn);
  const opacityValue = useSelector((state) => state.color.opacityValue);
  let exp = useSelector((state) => state?.fac);

  const dispatch = useDispatch();
  const location = useLocation();
  const data = useSelector((state) => state.fac.picture);
  const color = useSelector((state) => state.fac.color);

  const navigate = useNavigate();
  const imageUrl = data;
  const fileName = imageUrl.split("/").pop();
  const word = fileName.split(".")[0];
  const imageType = useSelector((state) => state?.fac?.imageType);

  let imageName = word;

  const [showModal1, setShowModal1] = useState(false);

  const [showModal2, setShowModal2] = useState(false);
  const [showTime, setShowTime] = useState(false);
  const [showExit, setShowExit] = useState(false);
  const [count, setCount] = useState(0);
  console.log("count", count);
  const [fullscreen, setFullscreen] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const isFullscreenEnabled = () => {
    return (
      document.fullscreenEnabled ||
      document.mozFullScreenEnabled ||
      document.webkitFullscreenEnabled ||
      document.msFullscreenEnabled
    );
  };

  const requestFullscreen = (element) => {
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen();
    }
  };

  const exitFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  };
  const handleFullscreen = () => {
    const elem = document.documentElement;
    if (isFullscreenEnabled()) {
      if (
        !document.fullscreenElement &&
        !document.mozFullScreenElement &&
        !document.webkitFullscreenElement &&
        !document.msFullscreenElement
      ) {
        requestFullscreen(elem);
      } else {
        exitFullscreen();
      }
    } else {
      setShowAlert(true); // Show the alert
    }
  };
  // useEffect(() => {
  //   if (showAlert) {
  //     // Show the alert when showAlert is true
  //     Swal.fire({
  //       icon: "warning",
  //       title: "Fullscreen Not Supported",
  //       text: "Fullscreen is not supported on this platform. You can use your browser's native fullscreen functionality.",
  //       confirmButtonColor: "#FF1616", // Background color
  //       confirmButtonTextColor: "#FFFFFF",
  //     });
  //     setShowAlert(false); // Reset showAlert state
  //   }
  // }, [showAlert]);

  const handleCount = () => {
    setCount(count + 1);
  };
  const doubleTapRef = useRef(null);
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  const [remainingTime, setRemainingTime] = useState(durationn * 60);
  const [meditationtime, setMeditationtime] = useState(0);
  const userId = useSelector((state) => state?.signupSlice?.user?._id);
  const [aggregationResult, setAggregationResult] = useState([]);
  console.log("userId", userId);

  const formatTime = () => {
    const minutes = Math.floor(remainingTime / 60);
    const seconds = remainingTime % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  useEffect(() => {
    axios
      .get(`${BASEURL}/api/focadots/${userId}`)
      .then((response) => {
        console.log("response12344", response);
        setAggregationResult(response?.data?.focadots);
        console.log(
          "(response?.data?.focadots.length",
          response?.data?.focadots.length
        );
        if (
          response?.data?.focadots?.length === 0 ||
          response?.data?.focadots === [] ||
          (response?.data?.focadots?.length - 3) % 4 === 0
        ) {
          const timer1 = setTimeout(() => {
            setShowModal1(true);
          }, 3000);

          // const timer2 = setTimeout(() => {
          //   setShowModal1(false);
          // }, 6000);
          // const timer21 = setTimeout(() => {
          //   setShowModal2(true);
          // }, 8000);

          // const timer3 = setTimeout(() => {
          //   setShowModal2(false);
          // }, 11000);

          return () => {
            clearTimeout(timer1);
            // clearTimeout(timer2);
            // clearTimeout(timer21);

            // clearTimeout(timer3);
          };
        }
      })
      .catch((error) => console.error(error));
  }, [userId]);
  function closeModal1() {
    setShowModal1(false);

    // After the first modal is closed, set a timer to open the second modal after 1 second
    setTimeout(() => {
      setShowModal2(true);
    }, 3000);
  }
  function closeModal2() {
    setShowModal2(false);
  }

  useEffect(() => {
    if (count >= 2) {
      const timer = setTimeout(() => {
        setCount(0);
      }, 1000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [count]);

  useEffect(() => {
    if (inView) {
      const timer = setInterval(() => {
        setRemainingTime((prevTime) => prevTime - 1);
      }, 1000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [inView]);

  useEffect(() => {
    if (inView) {
      const timer = setInterval(() => {
        setMeditationtime((prevTime) => prevTime + 1);
      }, 1000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [inView]);
  console.log(meditationtime, "123meditationTimeInMinutes");

  const minutes = Math.floor(meditationtime / 60);
  const remainingSeconds = meditationtime % 60;
  const formattedSeconds =
    remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds;
  console.log(minutes, ":", formattedSeconds, "formattedSeconds");
  let time = `${minutes}:${formattedSeconds}`;
  const meditationTimeInMinutes = (meditationtime / 60).toFixed(2);
  // console.log(meditationTimeInMinutes, "meditationTimeInMinutes");
  // console.log(meditationTimeInMinutes, "minutes");
  let medi = useSelector((state) => state.fac);
  medi = {
    ...medi,
    meditatioTime:
      meditationTimeInMinutes === "" ||
      meditationTimeInMinutes === undefined ||
      remainingTime <= 0
        ? durationn
        : meditationTimeInMinutes,
  };
  const saveTime = () => {
    axios
      .post(`${BASEURL}/api/addTime`, { time: time, by: userId })
      .then((response) => {})
      .catch((error) => {
        Swal.fire(error.message, "error");

        console.error(error);
      });
  };
  useEffect(() => {
    const mediTime = () => {
      if (remainingTime <= 0 && !exp._id) {
        saveTime();
        navigate("/savefocadot");
      }
      if (remainingTime <= 0 && exp?._id) {
        ///API call for time saving
        saveTime();
        navigate("/meditation");
      }
    };

    mediTime();
  }, [remainingTime]);

  // useEffect(() => {
  //   const timer1 = setTimeout(() => {
  //     setShowModal1(true);
  //   }, 3000);

  //   const timer2 = setTimeout(() => {
  //     setShowModal1(false);
  //   }, 6000);
  //   const timer21 = setTimeout(() => {
  //     setShowModal2(true);
  //   }, 8000);

  //   const timer3 = setTimeout(() => {
  //     setShowModal2(false);
  //   }, 11000);

  //   return () => {
  //     clearTimeout(timer1);
  //     clearTimeout(timer2);
  //     clearTimeout(timer21);

  //     clearTimeout(timer3);
  //   };
  // }, []);

  useEffect(() => {
    if (showTime || showExit) {
      const timer = setTimeout(() => {
        setShowTime(false);
        setShowExit(false);
      }, 5000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [showTime, showExit]);

  useEffect(() => {
    const handleDoubleTap = () => {
      setShowTime(true);
      setShowExit(true);
    };

    const doubleTapElement = doubleTapRef.current;

    if (doubleTapElement) {
      doubleTapElement.addEventListener("click", handleDoubleTap);
    }

    return () => {
      if (doubleTapElement) {
        doubleTapElement.removeEventListener("click", handleDoubleTap);
      }
    };
  }, []);

  const circleRef = useRef(null);

  const inhaleDuration = exp.customBreath === "custom" ? 5 : exp.inhaleSec;
  const inhaleHoldDuration =
    exp.customBreath === "custom" ? 5 : exp.inhaleHolSec;
  const exhaleDuration = exp.customBreath === "custom" ? 5 : exp.exhaleSec;
  const exhaleHoldDuration =
    exp.customBreath === "custom" ? 5 : exp.exhalholdSec;
  const d = 1;

  useEffect(() => {
    const circleElement = circleRef.current;

    const animateCircle = () => {
      circleElement.style.transform = "scale(0.1)"; // Set the scale to 0.5 to make it smaller
      // circleElement.style.opacity = "1"; // Reduce the opacity to make it less visible
      circleElement.style.transition = `transform ${d}ms ease-out, opacity ${d}ms ease-out`;

      setTimeout(() => {
        circleElement.style.transform = "scale(1)";
        // circleElement.style.opacity = "1";
        circleElement.style.transition = `transform ${inhaleDuration}s ease-in, opacity ${inhaleDuration}s ease-in`;

        setTimeout(() => {
          circleElement.style.transform = "scale(1)";
          // circleElement.style.opacity = "1";
          circleElement.style.transition = `transform ${inhaleHoldDuration}s ease-in-out, opacity ${inhaleHoldDuration}s ease-in-out`;

          setTimeout(() => {
            circleElement.style.transform = "scale(0.1)";
            // circleElement.style.opacity = "0.5";
            circleElement.style.transition = `transform ${exhaleDuration}s ease-out, opacity ${exhaleDuration}s ease-out`;

            setTimeout(() => {
              circleElement.style.transform = "scale(0.1)";
              // circleElement.style.opacity = "1"; // Set the opacity to 1 to keep it partially visible
              circleElement.style.transition = `transform ${exhaleHoldDuration}s ease-in-out, opacity ${exhaleHoldDuration}s ease-in-out`;

              setTimeout(() => {
                animateCircle();
              }, exhaleHoldDuration * 1000);
            }, exhaleDuration * 1000);
          }, inhaleHoldDuration * 1000);
        }, inhaleDuration * 1000);
      }, d * 10);
    };

    animateCircle();
  }, []);

  // const handleCount = () => {
  //   if (!showModal1 && !showModal2) {
  //     setShowModal1(true);
  //   } else if (showModal1 && !showModal2) {
  //     setShowModal2(true);
  //   }
  // };
  // useEffect(() => {
  //   if (inView) {
  //     const timer = setInterval(() => {
  //       setRemainingTime((prevTime) => prevTime - 1);
  //     }, 1000);

  //     return () => {
  //       clearInterval(timer);
  //     };
  //   } else {
  //     setRemainingTime(durationn * 60);
  //   }
  // }, [inView, durationn]);

  return (
    <div className="main_boxdiv">
      <div
        ref={ref}
        className="animare_box_div"
        onClick={handleCount}
        style={{
          backgroundImage: `url(${
            word === "one"
              ? one
              : word === "two"
              ? two
              : word === "three"
              ? three
              : word === "four"
              ? four
              : word === "five"
              ? five
              : word === "six"
              ? six
              : word === "seven"
              ? seven
              : word === "eight"
              ? eight
              : word === "nine"
              ? nine
              : word === "ten"
              ? ten
              : word === "eleven"
              ? eleven
              : word === "m1" && imageType === "mountain"
              ? m1
              : word === "m2" && imageType === "mountain"
              ? m2
              : word === "m3" && imageType === "mountain"
              ? m3
              : word === "m4" && imageType === "mountain"
              ? m4
              : word === "m5" && imageType === "mountain"
              ? m5
              : word === "m6" && imageType === "mountain"
              ? m6
              : word === "m7" && imageType === "mountain"
              ? m7
              : word === "m8" && imageType === "mountain"
              ? m8
              : word === "m9" && imageType === "mountain"
              ? m9
              : word === "m10" && imageType === "mountain"
              ? m10
              : word === "m11" && imageType === "mountain"
              ? m11
              : word === "m12" && imageType === "mountain"
              ? m12
              : word === "m13" && imageType === "mountain"
              ? m13
              : word === "m1" && imageType === "forest"
              ? f1
              : word === "m2" || (word === "f2" && imageType === "forest")
              ? f2
              : word === "m3" || (word === "f3" && imageType === "forest")
              ? f3
              : word === "m4" || (word === "f4" && imageType === "forest")
              ? f4
              : word === "m5" || (word === "f5" && imageType === "forest")
              ? f5
              : word === "m6" || (word === "f6" && imageType === "forest")
              ? f6
              : word === "m7" || (word === "f7" && imageType === "forest")
              ? f7
              : word === "m8" || (word === "f8" && imageType === "forest")
              ? f8
              : word === "m9" || (word === "f9" && imageType === "forest")
              ? f9
              : word === "m10" || (word === "f10" && imageType === "forest")
              ? f10
              : word === "m11" || (word === "f11" && imageType === "forest")
              ? f11
              : word === "m12" || (word === "f12" && imageType === "forest")
              ? f12
              : word === "m1" && imageType === "desert"
              ? d1
              : word === "m2" || (word === "d2" && imageType === "desert")
              ? d2
              : word === "m3" || (word === "d3" && imageType === "desert")
              ? d3
              : word === "m4" || (word === "d4" && imageType === "desert")
              ? d4
              : word === "m5" || (word === "d5" && imageType === "desert")
              ? d5
              : word === "m6" || (word === "d6" && imageType === "desert")
              ? d6
              : word === "m7" || (word === "d7" && imageType === "desert")
              ? d7
              : word === "m8" || (word === "d8" && imageType === "desert")
              ? d8
              : word === "m9" || (word === "d9" && imageType === "desert")
              ? d9
              : word === "m10" || (word === "d10" && imageType === "desert")
              ? d10
              : word === "m11" || (word === "d11" && imageType === "desert")
              ? d11
              : word === "m1" && imageType === "beach"
              ? b1
              : word === "m2" || (word === "b2" && imageType === "beach")
              ? b2
              : word === "m3" || (word === "b3" && imageType === "beach")
              ? b3
              : word === "m4" || (word === "b4" && imageType === "beach")
              ? b4
              : word === "m5" || (word === "b5" && imageType === "beach")
              ? b5
              : word === "m6" || (word === "b6" && imageType === "beach")
              ? b6
              : word === "m7" || (word === "b7" && imageType === "beach")
              ? b7
              : word === "m8" || (word === "b8" && imageType === "beach")
              ? b8
              : word === "m1" && imageType === "sky"
              ? s1
              : word === "m2" || (word === "s2" && imageType === "sky")
              ? s2
              : word === "m3" || (word === "s3" && imageType === "sky")
              ? s3
              : word === "m4" || (word === "s4" && imageType === "sky")
              ? s4
              : word === "m5" || (word === "s5" && imageType === "sky")
              ? s5
              : ""
          })`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="div1">
          <Modal
            className="custom-modal"
            visible={showModal1}
            footer={null}
            centered
          >
            <p className="protip">
              <span className="protip_red">TIP!</span>
              <br />
              Double tap the screen background to see your time remaining or to
              exit.
            </p>
            <p className="protipsmall">
              But try to avoid checking the time ;-)
            </p>

            <Link className="link_sign" onClick={closeModal1}>
              <div className="got_itbutton">
                <button onClick={() => setShowModal1(false)} className="got_it">
                  Thanks, got it!
                </button>
              </div>
            </Link>
          </Modal>
        </div>

        <div className="div2">
          <Modal
            className="custom-modal"
            visible={showModal2}
            footer={null}
            centered
          >
            <p className="protip protip_two">
              <span className="protip_red">PRO TIP!</span>
              <br />
              Play some music through your Spotify or YoutubePremium if you
              want!
            </p>
            <p className="protipsmall">
              We will build this into a later version of the app.
            </p>

            <div className="got_itbutton">
              <button onClick={() => setShowModal2(false)} className="got_it">
                Thanks, got it!
              </button>
            </div>
          </Modal>
        </div>
        <div className="div2">
          <Modal
            className="custom-modal"
            visible={showAlert}
            footer={null}
            centered
          >
            <p className="protip protip_two">
              <span className="protip_red">PRO TIP!</span>
              <br />
              Follow following Steps to go full screen
            </p>
            <div className="stepsOuter">
              <p className="barText">
                1. Click on A<span className="fontEDouble">A</span>, that is in
                your bottom of screen{" "}
              </p>
              <img src={tool1} className="img1" />
            </div>
            <div className="stepsOuter">
              <p className="barText">2.Click on "Hide Toolbar"</p>
              <img src={tool2} className="img1" />
            </div>
            <div className="stepsOuter">
              <p className="protip">To exit full screen click on link below</p>
              <img src={tool3} className="img3" />
            </div>
            <div className="got_itbutton">
              <button onClick={() => setShowAlert(false)} className="got_it">
                Thanks, got it!
              </button>
            </div>
          </Modal>
        </div>
        <div className="container1">
          <div className="animate_frame">
            <div
              style={{
                backgroundColor: `hsla(${color.hue}, ${color.saturation}%, ${color.luminosity}%)`,
                opacity: opacityValue / 100,
              }}
              ref={circleRef}
              className="img_frame2"
            ></div>
          </div>
          <div className="fullscreen-button">
            <FullscreenOutlined onClick={handleFullscreen} />
          </div>
          <div
            className="timeHow"
            style={{
              fontSize: "32px",
              fontWeight: "600",
              lineHeight: "38px",
              letterSpacing: "6%",
              alignItems: "center",
              color: "#6f00fe",
            }}
          >
            {count === 2 && remainingTime > 0 ? formatTime() : ""}
          </div>

          {count === 2 ? (
            <div className="button-class-exit">
              <Button
                type="primary"
                onClick={() => {
                  setRemainingTime(0);
                  setShowModal2(true);
                  dispatch(setField3(medi));
                }}
                className="exitBtnMian"
              >
                Exit
              </Button>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}

export default Animate;
