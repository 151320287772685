import React, { useState } from "react";

import focadot from "../../Images/Focadot.png";
import vector from "../../Images/Vector.png";
import { Link } from "react-router-dom";
import "./Duration.css";
import { useDispatch, useSelector } from "react-redux";
import { setField3 } from "../Redux/Forcadot";
import Swal from "sweetalert2";
function Duration() {
  let data = useSelector((state) => state.fac);
  console.log(data, "datadatadata");
  const [isHovered, setIsHovered] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [selectedValue, setSelectedValue] = useState(null);
  const [isFocused, setIsFocused] = useState(false);

  data = { ...data, durationn: selectedValue };

  const dispatch = useDispatch();
  const handleButtonClick = (value) => {
    setSelectedValue(value);
  };
  const handleHover = () => {
    setIsHovered(true);
  };
  const handleLeave = () => {
    setIsHovered(false);
  };
  const handleFocus = () => {
    setIsFocused(true);
    setIsHovered(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleInputChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const handleButtonClick1 = () => {
    if (!selectedValue) {
      Swal.fire({
        title: "Warning",
        text: "Please select Duration",
        icon: "warning",
        confirmButtonColor: "#6f00fe",
        confirmButtonClass: "#6f00fe-ok-button",
        confirmButtonText: '<span style="color:#FFFFFF">OK</span>',
      });
    } else {
      dispatch(setField3(data));
    }
  };

  return (
    <div>
      <div className="main_boxdiv">
        <div className="duration_box_div">
          {/* #6f00fe portion */}
          <div className="upper_portion">
            <div className="focadot_imgdiv">
              {/* <img src={focadot} /> */}
              <div className="logoWhite"></div>
            </div>

            <div className="vector_div">
              <Link className="link_sign" to="/welcome">
                <div className="vector_out">
                  <img src={vector} alt="Vector" />
                </div>
              </Link>
              <div className="dur_divs_text">
                <div className="dur_text">Duration</div>
              </div>
            </div>
          </div>

          {/* button section */}

          <div>
            <div className="dur_text_div">
              <p className="dur_p_text">
                How long would you like to meditate for?
              </p>

              <button
                className={`dur_button ${
                  selectedValue === "1" ? "active" : ""
                }`}
                onClick={() => handleButtonClick("1")}
              >
                1 minute
              </button>
              <button
                className={`dur_button ${
                  selectedValue === "3" ? "active" : ""
                }`}
                onClick={() => handleButtonClick("3")}
              >
                3 minutes
              </button>
              <button
                className={`dur_button five_minte ${
                  selectedValue === "5" ? "active" : ""
                }`}
                onClick={() => handleButtonClick("5")}
              >
                5 minutes
              </button>
              <button
                className={`dur_button five_minte ${
                  selectedValue === "10" ? "active" : ""
                }`}
                onClick={() => handleButtonClick("10")}
              >
                10 minutes
              </button>
              <div>
                <input
                  type="number"
                  placeholder={
                    isHovered || isFocused
                      ? "Enter number of minutes"
                      : "Custom"
                  }
                  className={`dur_button d_custom ${
                    selectedValue !== "1" ||
                    selectedValue !== "1" ||
                    (selectedValue !== "10" && selectedValue)
                      ? ""
                      : "active"
                  }`}
                  onChange={handleInputChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  onMouseEnter={handleHover}
                  onMouseLeave={handleLeave}
                />
              </div>

              <Link to={!selectedValue ? "" : "/breath"} className="link_sign">
                <button
                  className="dur_next_button"
                  onClick={handleButtonClick1}
                >
                  Next
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Duration;
