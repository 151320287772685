import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { login, setUser } from "../Redux/signupSlice";
import "../Almost/Almost.css";
import logo from "../../Images/logo1.png";
import Form from "react-bootstrap/Form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import axios from "axios";
import { BASEURL } from "../../utils/serverInstance";

const SignUp = () => {
  const location = useLocation();
  const navigate = useNavigate();

  console.log("INDEX almost", location);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    fName: "",
    email: "",
    password: "",
  });
  const data = useSelector((state) => state.fac);
  let exp = useSelector((state) => state.fac.Flow);
  console.log("formData", formData);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSignup = () => {
    if (!formData.fName || !formData.email || !formData.password) {
      Swal.fire({
        title: "Warning",
        text: "Please Enter All Fields",
        icon: "warning",
        confirmButtonColor: "#6f00fe",
        confirmButtonClass: "#6f00fe-ok-button",
        confirmButtonText: '<span style="color:#FFFFFF">OK</span>',
      });
    } else {
      axios
        .post(`${BASEURL}/api/registerCustomer`, formData)
        .then((response) => {
          console.log("userSingnup Detail", response);
          dispatch(setUser(response?.data.user));
          Swal.fire({
            title: response.data.message,
            icon: "success",
            iconColor: "#6f00fe",
            showCloseButton: true,
            showCancelButton: false,
            confirmButtonColor: "#6f00fe",
            confirmButtonText: "OK",
            position: "center",
            customClass: {
              container: "swal-container",
              content: "swal-content",
              actions: "swal-actions",
              confirmButton: "custom-#6f00fe",
              icon: "custom-#6f00fe",
            },
            onOpen: () => {
              const swalContent =
                Swal.getPopup().querySelector(".swal-content");
              if (swalContent) {
                swalContent.classList.add("swal-content-custom");
              }
              const successIcon = Swal.getPopup().querySelector(
                ".swal-icon--#6f00fe"
              );
              if (successIcon) {
                successIcon.style.color = "#6f00fe";
              }
            },
          });

          if (response.data.message) {
          }
          if (exp === "Flow1") {
            navigate("/Animate");
          } else if (exp === "Flow2") {
            navigate("/back");
          }

          // Handle the response data
          console.log(response.data, "responsignup");
        })
        .catch((error) => {
          Swal.fire({
            title: "Warning",
            text: error.response.data.message,
            icon: "warning",
            confirmButtonColor: "#6f00fe",
            confirmButtonClass: "#6f00fe-ok-button",
            confirmButtonText: '<span style="color:#FFFFFF">OK</span>',
          });
          console.error(error);
        });

      // try {
      //   const response = await axios.post(
      //     "",
      //     formData
      //   );
      //   console.log("response", response.data);
      //   Swal.fire(response.data.message, "success");
      //   if (response) {
      //     // window.location.href = "/sign"; //

      //   }
      //   return response.data;
      // } catch (error) {
      //   Swal.fire(error.response.data.message, "error");
      //   console.log("11111111", error);
      //   throw new Error(error.message);
      // }

      // dispatch(signup(formData));
    }
  };

  return (
    <div>
      <div className="main_boxdiv">
        <div className="sign_box_div">
          {/* #6f00fe portion */}
          <div className="sign_portion">
            <div className="sign_div">
              <span className="sign_text">Focadot</span>
              <img className="sign_logo" src={logo} />
            </div>
          </div>

          {/* lower portion */}
          <div>
            <div className="alm_text_div">
              <p className="alm_p_text">Welcome Focadota!</p>

              <input
                className="alm_input"
                placeholder="First name"
                type="text"
                name="fName"
                value={formData.fName}
                onChange={handleChange}
              />
              <input
                className="alm_input email"
                placeholder="Email"
                type="email"
                name="email"
                value={formData.email}
                pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}"
                required
                onChange={handleChange}
              />
              {formData.email &&
                !formData.email.match(
                  "[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}"
                ) && (
                  <p className="error" style={{ color: "#6f00fe" }}>
                    Enter a valid email format
                  </p>
                )}
              <input
                className="alm_input passw"
                placeholder="Password"
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
              />

              <Link className="link_sign">
                {" "}
                <button className="alm_next_button" onClick={handleSignup}>
                  Sign Up & Meditate!
                </button>
              </Link>
            </div>
            <div className="loginArea">
              <Link to="/sign" className="orlogin">
                Or Sign In
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
