import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setField3 } from "../Redux/Forcadot";
import { login, setUser } from "../Redux/signupSlice";
import "../SignIn/SignIn.css";
import logo from "../../Images/logo1.png";
import Form from "react-bootstrap/Form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import axios from "axios";
import { BASEURL } from "../../utils/serverInstance";
import { navigate } from "react-router-dom";

const AdminSignIn = () => {
  const navigate = useNavigate();
  const [flow, setFlow] = useState("");
  const dispatch = useDispatch();
  let exp = useSelector((state) => state.fac);
  exp = { ...exp, Flow: "Flow" };

  const handleFlow1 = () => {
    exp = { ...exp, Flow: "Flow3" }; // Update the exp state here

    dispatch(setField3(exp));
  };

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${BASEURL}/api/loginAdmin`, formData);
      console.log("userlogin Detail", response.data.token);

      const token = response.data.token;
      sessionStorage.setItem("token", response.data.token);

      dispatch(setUser(response?.data.user));
      Swal.fire({
        title: response.data.message,
        showCancelButton: false,
        confirmButtonColor: "#6f00fe",
        confirmButtonText: "Ok",
        customClass: {
          confirmButton: "custom-confirm-button",
        },
      });
      navigate("/behavior");
    } catch (err) {
      Swal.fire({
        title: err.response.data.message,
        showCancelButton: false,
        confirmButtonColor: "#6f00fe",
        confirmButtonText: "Ok",
        customClass: {
          confirmButton: "custom-confirm-button",
        },
      });
    }
  };
  return (
    <div>
      <div className="main_boxdiv">
        <div className="sign_box_div">
          {/* #6f00fe portion */}
          <div className="sign_portion">
            <div className="sign_div">
              <span className="sign_text">Focadot</span>
              <img className="sign_logo" src={logo} />
            </div>
          </div>

          {/* lower portion */}
          <div className="sign_lowerdiv">
            <p className="sign_wel">Admin login</p>

            <Form onSubmit={handleSubmit}>
              <Form.Control
                type="email"
                placeholder="Email"
                className="sign_fname sign_bot"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
              <Form.Control
                type="password"
                placeholder="Password"
                className="sign_fname sign_botms"
                name="password"
                passwrod
                value={formData.password}
                onChange={handleChange}
              />
              <div className="forgetArea">
                <button type="submit" className="sign_button">
                  Sign In
                </button>
                {/* <Link to="/forgot" className="forgetText" onClick={handleFlow1}>
                  <div className="sign_sign">Forgot Password</div>
                </Link> */}
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminSignIn;
