import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setField3 } from "../Redux/Forcadot";
import { login } from "../Redux/signupSlice";
import logo from "../../Images/logo1.png";
import Form from "react-bootstrap/Form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import "../SignIn/SignIn.css";

// import withReactContent from "sweetalert2-react-content";
import axios from "axios";
import { BASEURL } from "../../utils/serverInstance";
import focadot from "../../Images/badge.png";

function ResetPassword() {
  const dispatch = useDispatch();
  let exp = useSelector((state) => state.fac);
  exp = { ...exp, Flow: "Flow" };

  const handleFlow1 = () => {
    exp = { ...exp, Flow: "Flow2" }; // Update the exp state here

    dispatch(setField3(exp));
  };

  const [formData, setFormData] = useState({
    password: "",
  });
  const navigate = useNavigate();
  const location = useLocation();
  const resetToken = new URLSearchParams(location.search).get("token");
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.password) {
      Swal.fire({
        title: "error",
        text: "Please save your password",
        icon: "error",
        confirmButtonColor: "#6f00fe",
        confirmButtonClass: "#6f00fe-ok-button",
        confirmButtonText: '<span style="color:#FFFFFF">OK</span>',
      });
    } else {
      axios
        .post(`${BASEURL}/api/reset`, {
          token: resetToken,
          newPassword: formData.password,
        })
        .then((response) => {
          Swal.fire({
            title: response.data.message,
            icon: "success",
            iconColor: "#6f00fe",
            showCloseButton: true,
            showCancelButton: false,
            confirmButtonColor: "#6f00fe",
            confirmButtonText: "OK",
            position: "center",
            customClass: {
              container: "swal-container",
              content: "swal-content",
              image: "swal-image",
              actions: "swal-actions",
            },
            onOpen: () => {
              const swalContent =
                Swal.getPopup().querySelector(".swal-content");
              if (swalContent) {
                swalContent.classList.add("swal-content-custom");
              }
            },
          })
            .then((result) => {
              if (result.isConfirmed) {
                // Perform your navigation here
                navigate("/sign");
              }

              // Handle the response data
              console.log(response.data);
            })
            .catch((error) => {
              Swal.fire({
                title: error.response.data.message,
                showConfirmButton: true,
                confirmButtonColor: "#6f00fe",
                confirmButtonText: '<span style="color: white">OK</span>',
              });
              console.error(error);
            });
        });
    }
  };
  return (
    <div>
      <div className="main_boxdiv">
        <div className="sign_box_div">
          {/* #6f00fe portion */}
          <div className="sign_portion">
            <div className="sign_div">
              <span className="sign_text">Focadot</span>
              <img className="sign_logo" src={logo} />
            </div>
          </div>

          {/* lower portion */}
          <div className="sign_lowerdiv">
            <p className="sign_wel">Reset Password!</p>

            <Form onSubmit={handleSubmit}>
              <Form.Control
                type="password"
                placeholder="Enter New Password"
                className="sign_fname sign_bot"
                name="password"
                value={formData.password}
                onChange={handleChange}
              />

              <button
                type="submit"
                className="sign_button"
                onClick={handleFlow1}
              >
                Submit
              </button>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
